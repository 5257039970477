import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import api from './config';
import {
    username,
    password
} from './credentials';
import Axios from 'axios';


Vue.use(Vuex);

export default {
    state: {
        disbursementList: [],
        disbursement: {},
        repaymentList: [],
        repayment: {},
        remainingPaymentList: [],
        remainingPaymentDetail: {},
        userInformation: {},
        loanProductList: [],
        bulkDisbursementList: [],
        bulkDisbursementSummary: [],
        bulkRepaymentList: [],
        bulkRepaymentSummary: [],
    },
    getters: {
        disbursementList(state) {
            return state.disbursementList;
        },
        disbursement(state) {
            return state.disbursement;
        },
        repaymentList(state) {
            return state.repaymentList;
        },
        repayment(state) {
            return state.repayment;
        },
        remainingPaymentList(state) {
            return state.remainingPaymentList;
        },
        remainingPaymentDetail(state) {
            return state.remainingPaymentDetail;
        },
        userInformation(state) {
            return state.userInformation;
        },
        loanProductList(state) {
            return state.loanProductList
        },
        bulkDisbursementList(state) {
            return state.bulkDisbursementList;
        },
        bulkDisbursementSummary(state) {
            return state.bulkDisbursementSummary;
        },
        bulkRepaymentList(state) {
            return state.bulkRepaymentList;
        },
        bulkRepaymentSummary(state) {
            return state.bulkRepaymentSummary;
        }
    },
    mutations: {
        setLoading(state, { isLoading, key, error }) {
            state[key] = {
                ...state[key],
                isLoading,
                error,
            };
        },
        cleanStorage(state) {
            state.disbursementList = [];
            state.disbursement = {};
        },
        updateDisbursementList(state, data) {
            state.disbursementList = data;
            state.disbursementList.isLoading = false;
        },
        updateDisbursementDetail(state, data) {
            state.disbursement = data;
            state.disbursement.isLoading = false;
        },
        updateRepaymentList(state, data) {
            state.repaymentList = data;
            state.repaymentList.isLoading = false;
        },
        updateRepaymentDetail(state, data) {
            state.repayment = data;
            state.repayment.isLoading = false;
        },
        updateRemaingPaymentList(state, data) {
            state.remainingPaymentList = data;
            state.remainingPaymentList.isLoading = false;
        },
        updateRemainingPaymentDetail(state, data) {
            state.remainingPaymentDetail = data;
            state.remainingPaymentDetail.isLoading = false;
        },
        updateUserInformation(state, data) {
            state.userInformation = data;
            state.userInformation.isLoading = false;
        },
        updateLoanProductList(state, data) {
            state.loanProductList = data;
            state.loanProductList.isLoading = false;
        },
        updateBulkDisbursementList(state, data) {
            state.bulkDisbursementList = data;
            state.bulkDisbursementList.isLoading = false;
        },
        updateBulkDisbursementSummary(state, data) {
            state.bulkDisbursementSummary = data;
            state.bulkDisbursementSummary.isLoading = false;
        },
        updateBulkRepaymentList(state, data) {
            state.bulkRepaymentList = data;
            state.bulkRepaymentList.isLoading = false;
        },
        updateBulkRepaymentSummary(state, data) {
            state.bulkRepaymentSummary = data;
            state.bulkRepaymentSummary.isLoading = false;
        }
    },
    actions: {
        async fetchDisbursementList({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'disbursementList' })

            const user = JSON.parse(localStorage.getItem('vuex'))
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }

                const url = `${api}/loan-publisher-internal/api/v1/payment/disbursement/list`

                try {
                    const response = await Axios.get(url, { headers, params })

                    if (response.status === 200) {
                        commit('updateDisbursementList', response.data.data);
                        return response;
                    } else {
                        throw new Error(message);
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'disbursementList',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async fetchDisbursementDetail({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'disbursement' })

            const user = JSON.parse(localStorage.getItem('vuex'))
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }

                const url = `${api}/loan-publisher-internal/api/v1/payment/disbursement`

                try {
                    const response = await Axios.get(url, { headers, params })

                    if (response.status === 200) {
                        commit('updateDisbursementDetail', response.data.data);
                        return response
                    }
                } catch (error) {
                    commit('updateDisbursementDetail', {
                        borrowerDetail: {
                            bankAccountName: '-',
                            bankAccountNumber: '-',
                            bankName: '-',
                            borrowerName: '-',
                        }, disburseDetail: {
                            disbursementAmount: '-',
                            disbursementId: '-',
                            feesAmount: '-',
                            interestAmount: '-',
                            loanAmount: '-',
                            canDisburse: false
                        }, loanDetail: {
                            loanNumber: '-',
                            loanTenor: '-',
                            loanTenorPeriod: '-',
                            loanType: '-',
                        },
                    });
                    commit('setLoading', {
                        isLoading: false,
                        key: 'disbursement',
                        error: error.response.data,
                    });
                    return error.response
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async postDisbursement({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'postDisbursement' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }
                let apiVersion;
                if (params.paymentMethod === 'kpay') {
                    apiVersion = 'v1'
                } else {
                    apiVersion = 'v2'
                }
                const url = `${api}/loan-publisher-internal/api/${apiVersion}/payment/disbursement` + '?disbursementId=' + params.disbursementId
                    + '&userFullname=' + params.userFullname;

                try {
                    const response = await Axios.post(url, params, { headers })

                    console.log(response);
                    if (response.status === 200) {
                        return response;
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'postDisbursement',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async fetchRepaymentList({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'repaymentList' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("session")).token
                }

                const url = `${api}/kancil/loan/oauth2/apis/v1/admin/payment/repayment-list`

                try {
                    const response = await Axios.get(url, { headers, params });
                    if (response.status === 200) {
                        commit('updateRepaymentList', response.data.data);
                        return response;
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        commit('cleanStorage');
                    }
                    commit('setLoading', {
                        isLoading: false,
                        key: 'repaymentList',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async fetchRepaymentDetail({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'repayment' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("session")).token
                }

                const url = `${api}/kancil/loan/oauth2/apis/v1/admin/payment/repayment`

                try {
                    const response = await Axios.get(url, { headers, params })

                    if (response.status === 200) {
                        commit('updateRepaymentDetail', response.data.data);
                        return response
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        commit('cleanStorage');
                    }
                    commit('updateRepaymentDetail', {
                        repaymentDetail: {
                            borrowerInterestPercentage: '-',
                            borrowerInterestAmount: '-',
                            lateDays: '-',
                            penaltyAmount: '-',
                            repaymentStatusName: '-',
                            lenderInterestPercentage: '-',
                            taxAmount: '-',
                        }, loanDetail: {
                            loanNumber: '-',
                            loanType: '-',
                            loanAmount: '-',
                            loanTenor: '-',
                            loanTenorPeriod: '-',
                        }, lenderDetail: {
                            interestAmount: '-',
                            interestPercentage: '-',
                            investedAmount: '-',
                            lenderName: '-',
                            penaltyAmount: '-',
                            repaymentAmount: '-',
                            taxAmount: '-'
                        },
                    });
                    commit('setLoading', {
                        isLoading: false,
                        key: 'repayment',
                        error: error.response.data,
                    });
                    return error.response
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async postRepayment({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'postRepayment' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("session")).token
                }

                // let apiVersion;
                // if(params.paymentMethod === 'kpay'){
                //     apiVersion = 'v1'
                // }else{
                //     apiVersion = 'v2'
                // }
                const url = `${api}/kancil/loan/oauth2/apis/v1/admin/payment/repayment` + '?repaymentId=' + params.repaymentId;

                try {
                    const response = await Axios.put(url, params, { headers })
                    console.log(response);
                    if (response.status === 200) {
                        console.log(response.data.data);
                        return response;
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        commit('cleanStorage');
                    }
                    commit('setLoading', {
                        isLoading: false,
                        key: 'postRepayment',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async fetchRemainingPaymentList({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'remainingPaymentList' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }

                const url = `${api}/loan-publisher-internal/api/v1/payment/remaining-payment/list`

                try {
                    const response = await Axios.get(url, { headers, params });

                    if (response.status === 200) {
                        commit('updateRemaingPaymentList', response.data.data);
                        return response;
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'remainingPaymentList',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async fetchRemainingPaymentDetail({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'remainingPaymentDetail' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }

                const url = `${api}/loan-publisher-internal/api/v1/payment/remaining-payment`

                try {
                    const response = await Axios.get(url, { headers, params })

                    if (response.status === 200) {
                        commit('updateRemainingPaymentDetail', response.data.data);
                        return response
                    }
                } catch (error) {
                    commit('updateRemainingPaymentDetail', {
                        borrowerDetail: {
                            bankAccountName: '-',
                            bankAccountNumber: '-',
                            bankName: '-',
                            borrowerName: '-',
                        }, loanDetail: {
                            loanNumber: '-',
                            loanTenor: '-',
                            loanTenorPeriod: '-',
                            loanType: '-',
                        }, remainingPaymentDetail: {
                            feesAmount: '-',
                            interestAmount: '-',
                            loanAmount: '-',
                            remainingPaymentAmount: '-',
                            remainingPaymentId: '-',
                        },
                    });
                    commit('setLoading', {
                        isLoading: false,
                        key: 'remainingPaymentDetail',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async postRemainingPayment({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'postRepayment' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }
                let apiVersion;
                if (params.paymentMethod === 'kpay') {
                    apiVersion = 'v1'
                } else {
                    apiVersion = 'v2'
                }
                const url = `${api}/loan-publisher-internal/api/${apiVersion}/payment/remaining-payment` + '?remainingPaymentId=' + params.remainingPaymentId;

                try {
                    const response = await Axios.post(url, params, { headers })

                    if (response.status === 200) {
                        console.log(response.data.data);
                        return response;
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'postRepayment',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async fetchUserInformation({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'userInformation' })

            const user = JSON.parse(localStorage.getItem('vuex'))
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }

                const url = `${api}/kancil/user/auth/apis/v1/admin/account-info`

                try {
                    const response = await Axios.get(url, { headers, params })

                    if (response.status === 200) {
                        commit('updateUserInformation', response.data.data);
                        return response;
                    } else {
                        throw new Error(message);
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'userInformation',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                console.log('here');
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async fetchLoanProductList({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'loanProductList' })

            const user = JSON.parse(localStorage.getItem('vuex'))
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }

                const url = `${api}/loan-publisher-internal/api/v1/admin/loan-product/list`

                try {
                    const response = await Axios.get(url, { headers, params })

                    if (response.status === 200) {
                        commit('updateLoanProductList', response.data.data);
                        return response;
                    } else {
                        throw new Error(message);
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'loanProductList',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async fetchBulkDisbursementList({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'bulkDisbursementList' })
            const user = JSON.parse(localStorage.getItem('vuex'))
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }
                let modifiedParams;
                const loanType = params.loanType;
                if (loanType.length > 0) {
                    const loanTypeString = loanType.join(',');
                    modifiedParams = {
                        ...params,
                        loanType: loanTypeString,
                    };
                }
                else {
                    modifiedParams = {
                        ...params
                    }
                }

                const url = `${api}/loan-publisher-internal/api/v1/payment/disbursement/list`

                try {
                    const response = await Axios.get(url, { headers, params: modifiedParams })

                    if (response.status === 200) {
                        commit('updateBulkDisbursementList', response.data.data);
                        return response;
                    } else {
                        throw new Error(message);
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'bulkDisbursementList',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async postDisbursementSummary({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'bulkDisbursementSummary' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }

                const url = `${api}/loan-publisher-internal/api/v1/payment/disbursement-summary`;

                try {
                    const response = await Axios.post(url, params, { headers })

                    if (response.status === 200) {
                        commit('updateBulkDisbursementSummary', response.data.data);
                        return response;
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'bulkDisbursementSummary',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async postBulkDisbursement({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'postBulkDisbursement' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }

                const url = `${api}/loan-publisher-internal/api/v1/payment/bulk-disbursement`;

                try {
                    const response = await Axios.post(url, params, { headers })

                    if (response.status === 200) {
                        localStorage.removeItem("bulkDisburseData");
                        return response;
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'postBulkDisbursement',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async fetchBulkRepaymentList({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'bulkRepaymentList' })

            const user = JSON.parse(localStorage.getItem('vuex'))
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("session")).token
                }

                let modifiedParams;
                const loanType = params.loanType;
                if (loanType.length > 0) {
                    const loanTypeString = loanType.join(',');
                    modifiedParams = {
                        ...params,
                        loanType: loanTypeString,
                    };
                }
                else {
                    modifiedParams = {
                        ...params
                    }
                }
                const url = `${api}/kancil/loan/oauth2/apis/v1/admin/payment/repayment-list`

                try {
                    const response = await Axios.get(url, { headers, params: modifiedParams })

                    if (response.status === 200) {
                        commit('updateBulkRepaymentList', response.data.data);
                        return response;
                    } else {
                        throw new Error(message);
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'bulkRepaymentList',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async postRepaymentSummary({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'bulkRepaymentSummary' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("session")).token
                }

                const url = `${api}/kancil/loan/oauth2/apis/v1/admin/repayment-summary`;

                try {
                    const response = await Axios.post(url, params, { headers })

                    if (response.status === 200) {
                        commit('updateBulkRepaymentSummary', response.data.data);
                        return response;
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        key: 'updateBulkRepaymentSummary',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async postBulkRepayment({ commit }, params) {
            commit('setLoading', { isLoading: true, key: 'postBulkRepayment' })

            const user = JSON.parse(localStorage.getItem('vuex'));
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("session")).token
                }

                const url = `${api}/kancil/loan/oauth2/apis/v1/admin/repayment-summary/submit`;

                try {
                    const response = await Axios.post(url, params, { headers })
                    console.log(response);
                    if (response.status === 200) {
                        localStorage.removeItem("bulkRepaymentData");
                        return response;
                    }
                } catch (error) {
                    if (error.response.status === 401) {
                        commit('cleanStorage');
                    }
                    commit('setLoading', {
                        isLoading: false,
                        key: 'postBulkRepayment',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        },
        async getLenderList({ commit }, params) {
            // commit('setLoading', { isLoading: true, key: 'bulkRepaymentList' })
            const user = JSON.parse(localStorage.getItem('vuex'))
            if (user.currentUser.hasOwnProperty('session')) {
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(username + ':' + password)
                }

                const url = `${api}/identity-provider-admin/api/v1/lender-list`;

                try {
                    const response = await Axios.get(url, { headers, params: params })

                    if (response.status === 200) {
                        // commit('updateBulkRepaymentList', response.data.data);
                        return response.data;
                    } else {
                        throw new Error(message);
                    }
                } catch (error) {
                    commit('setLoading', {
                        isLoading: false,
                        // key: 'bulkRepaymentList',
                        error: error.response.data,
                    });
                    return error
                }
            } else {
                commit('cleanStorage');
                localStorage.clear();
                window.location.href = '/login';
            }
        }
    },
    plugins: [persistedState()]
}
