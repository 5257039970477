import moment from 'moment';
import convertCurrency from '@/helper/convertToCurrency';
import PinModal from '@/components/modal/pinmodal/index';

export default {
  name: 'repayment-detail',
  components: { PinModal },
  data() {
    return {
      showInputPin: false,
      // arrayStatus: ["Paid", "Overdue"],
      // arrayMethod: ["Cash", "Transfer"],
      // status: 'Paid',
      // method: 'Transfer',
      param: {},
    }
  },
  computed: {
    detailRepayment() {
      return this.$store.state.dataRepaymentDetail.repayment;
    },
    title() {
      return this.data.title;
    }
  },
  methods: {
    showPinModal() {
      this.param = {
        'loanId': this.data.item.loanId,
        'sequence': this.data.item.repaymentSeqNo,
        // 'status': this.status,
        // 'method': this.method,
        'action': '/repayment',
        'type': 'Repayment',
      }
      this.showInputPin = !this.showInputPin;
    },
    onModalClose(){
      this.showInputPin = !this.showInputPin;
    },
    backToList() {
      this.$props.close();
    },
  },
  props: {
    close: {
      type: Function,
    },
    data: Object,
  },
  filters: {
    formatDate: function(value) {
      if (value !== 0) {
        return moment.unix(value).format('DD/MM/YYYY');
      } else {
        return '-';
      }
    },
    formatCurrency: function(value) {
      if(value){
        return `Rp ${convertCurrency(value)}`
      }

      return 'Rp 0';
    },
  },
}
