<template>
    <div>
        <div class="row">
            <div class="col-sm-8">
                <legend>Summary</legend>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div
                        class="col-lg-12"
                        v-if="bulkDisbursementSummary.length > 0"
                    >
                        <card
                            v-for="(disburse, index) in bulkDisbursementSummary"
                            :key="index"
                        >
                            <template slot="header">
                                <h6 class="card-title">
                                    Summary Detail {{ index + 1 }}
                                </h6>
                                <hr />
                            </template>
                            <div class="content">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Total Principal Amount
                                            </div>
                                            <label
                                                v-if="
                                                    disburse.principalAmount ===
                                                        0
                                                "
                                                ><b>Rp0</b></label
                                            >
                                            <label
                                                v-else-if="
                                                    disburse.principalAmount > 0
                                                "
                                                ><b>{{
                                                    disburse.principalAmount
                                                        | formatCurrency
                                                }}</b></label
                                            >
                                            <label v-else><b>-</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Total Disbursement Amount
                                            </div>
                                            <label
                                                v-if="
                                                    disburse.disburseAmount ===
                                                        0
                                                "
                                                ><b>Rp0</b></label
                                            >
                                            <label
                                                v-else-if="
                                                    disburse.disburseAmount > 0
                                                "
                                                ><b>{{
                                                    disburse.disburseAmount
                                                        | formatCurrency
                                                }}</b></label
                                            >
                                            <label v-else><b>-</b></label>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Bank Account Number
                                            </div>
                                            <label
                                                ><b>{{
                                                    disburse.accountNumber
                                                }}</b></label
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Bank Account Name
                                            </div>
                                            <label
                                                ><b>{{
                                                    disburse.accountName
                                                }}</b></label
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Bank Name
                                            </div>
                                            <label
                                                ><b>{{
                                                    disburse.bankName
                                                }}</b></label
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <button
            class="float"
            id="approve"
            @click="showPinModal"
            :disabled="disableButton()"
            :style="{ backgroundColor: disableButton() ? 'gray' : '#41B883' }"
        >
            <span class="my-float">DISBURSE</span>
        </button>

        <pin-modal
            v-if="showInputPin"
            :close="onModalClose"
            :reqParam="reqParam"
            :param="param"
        />
    </div>
</template>

<script>
import convertCurrency from "@/helper/convertToCurrency";
import $store from "../../../../stores/store";
import { mapGetters } from "vuex";
import PinModal from "../../modal/pin-modal";

export default {
    components: { PinModal },
    computed: {
        ...mapGetters(["bulkDisbursementSummary"])
    },
    created() {
        this.getBulkDisburseId();
        this.getDisburseSummary();
        this.reqParam = {
            disbursementIds: this.bulkDisburseId,
            type: "bulkDisburse"
        };
    },
    data() {
        return {
            showInputPin: false,
            bulkDisburseId: [],
            reqParam: {}
        };
    },
    methods: {
        getBulkDisburseId() {
            this.bulkDisburseId = JSON.parse(
                localStorage.getItem("bulkDisburseData")
            );
        },
        getDisburseSummary() {
            const bodyRequest = {
                disbursementIds: this.bulkDisburseId
            };
            this.$store
                .dispatch("postDisbursementSummary", bodyRequest)
                .then(() => {});
        },
        showPinModal() {
            this.showInputPin = !this.showInputPin;
        },
        onModalClose() {
            this.showInputPin = !this.showInputPin;
        },
        navigateToList() {
            this.$router.push("/payment-panel/bulk-disbursement");
            localStorage.removeItem("bulkDisburseData");
        },
        disableButton() {
            var op = "Operation";
            var ittech = "IT Tech";

            if (
                JSON.parse(localStorage.getItem("session")).userRole === op ||
                JSON.parse(localStorage.getItem("session")).userRole === ittech
            ) {
                //OP or IT Tech is not allowed to edit
                return true;
            } else {
                return false;
            }
        }
    },
    filters: {
        formatCurrency: function(value) {
            if (value) {
                return `Rp${convertCurrency(value)}`;
            }
        }
    }
};
</script>

<style scoped></style>
