<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
  .vue-notifyjs.notifications {
    .alert {
      z-index: 10000;
    }
    .list-move {
      transition: transform 0.3s, opacity 0.4s;
    }
    .list-item {
      display: inline-block;
      margin-right: 10px;
    }
    .list-enter-active {
      transition: transform 0.2s ease-in, opacity 0.4s ease-in;
    }
    .list-leave-active {
      transition: transform 1s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
      opacity: 0;
      transform: scale(1.1);
    }
    .list-leave-to {
      opacity: 0;
      transform: scale(1.2, 0.7);
    }
  }

  .md-table-row {
    cursor: pointer;
  }

  @media screen and (min-width: 1680px) {
    .md-card {
      height: 817px !important;
    }
    .md-table-content {
      /* height: 817px !important; */
      max-height: 817px !important;
    }
  }

  @media screen and (max-width: 1440px) {
    .md-card {
      height: 670px !important;
    }
    .md-table-content {
      height: 670px !important;
      max-height: 670px !important;
    }
  }

  .md-card {
    border-radius: 6px !important;
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5)!important;
    background-color: #FFFFFF;
    color: #252422;
    position: relative;
    z-index: 1;
    border: none;
  }

  .modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    display: table;
    transition: opacity .3s ease;
    z-index: 1010;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-title {
    position: absolute;
    padding-top: 5px;
  }

  .pin-modal {
    margin: 12px 0;
    font-family: inherit;
    font-size: 15px;
    padding: 16px;
    text-align: center;
    border: none;
    border-bottom: 2px solid #3498db;
    outline: none;
    border-radius: 5px;
    margin-right: 20px;
    width: 50px;
    background-color: #f0f0f0;
    -webkit-text-security: disc;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  #cover-spin {
    position:fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index:9999;
    padding: 30% 0 0 55%;
    // display:none;
  }

  // @-webkit-keyframes spin {
  //   from {-webkit-transform:rotate(0deg);}
  //   to {-webkit-transform:rotate(360deg);}
  // }

  // @keyframes spin {
  //   from {transform:rotate(0deg);}
  //   to {transform:rotate(360deg);}
  // }

  // #cover-spin::after {
  //   content:'';
  //   display:block;
  //   position:absolute;
  //   left:50%;
  //   top: 40%;
  //   width: 45px;
  //   height: 45px;
  //   border-style: solid;
  //   border-color: black;
  //   border-top-color: transparent;
  //   border-width: 6px;
  //   border-radius:50%;
  //   -webkit-animation: spin .8s linear infinite;
  //   animation: spin .8s linear infinite;
  // }

  .float-add{
    position: fixed;
    bottom: 40px;
    right: 70px;
    background-color: #41B883;
    color: #ffffff;
    padding: 20px;
    font-size: 1.5em;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 2;
    cursor: pointer;
  }

  .float-add:hover{
    background-color: #3aa575;
  }

  .float-add:active{
    background-color: #349368;
  }

  .my-float{
    margin-top:22px;
  }

  @media screen {
    .float-add{
      position: fixed;
      bottom: 40px;
      right: 70px;
      background-color: #41B883;
      color: #ffffff;
      padding: 20px;
      font-size: 1.5em;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      text-align: center;
      box-shadow: 2px 2px 3px #999;
      z-index: 2;
      cursor: pointer;
    }
  }
</style>
