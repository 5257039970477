import Loader from 'vue-spinner/src/PulseLoader';

export default {
  name: 'pinmodal',
  components: { Loader },
  data() {
    return {
      defaultPin: 123456,
      pin1: '',
      pin2: '',
      pin3: '',
      pin4: '',
      pin5: '',
      pin6: '',
      ok: true,
      errorMessage: '',
      showLoader: false,
    }
  },
  mounted() {
    this.$refs.pin1.focus();
  },
  props: {
    close: {
      type: Function,
    },
    param: {
      type: Array
    },
  },
  methods: {
    closeModal() {
      this.$props.close();
    },
    inputPin(e) {
      let regex = /^-?\d*\.?\d*$/;
      if (!regex.test(e.target.value)) {
        e.target.value = e.target.value.slice(0, -1);
      } else {
        if (e.inputType == "insertText" && this.pin6 == ''){
          e.target.nextElementSibling.focus();

        } else if (e.inputType == "deleteContentBackward" && this.pin1 != ''){
          e.target.previousElementSibling.focus();
          this.ok = true;

        } else {
          var pin = [this.pin1, this.pin2, this.pin3, this.pin4, this.pin5, this.pin6];

          if(pin.length == '6'){
            this.ok = false;
            this.errorMessage = '';
          }
        }
      }
    },
    checkPin() {
      this.showLoader = true;
      this.ok = true;

      var pin = [this.pin1, this.pin2, this.pin3, this.pin4, this.pin5, this.pin6];
      pin = parseInt(pin.join("")).toString();
      this.$store.dispatch('doCheckPin', pin).then((res) => {
        if(res.status == 'success') {
          this.showLoader = false;
          this.ok = false;
          this.approve();
        } else {
          this.ok = false;
          this.showLoader = false;
          this.$notify({
            title: 'Failed',
            message: res.message,
            icon: "ti-check",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
          });
          this.$refs.pin1.focus();
          this.pin = [];
          this.pin1 = '';
          this.pin2 = '';
          this.pin3 = '';
          this.pin4 = '';
          this.pin5 = '';
          this.pin6 = '';
        }
      })
      // this.approve();
    },
    approve() {
      this.showLoader = true;
      if(this.param.length > 1){
        for (let i = 0; i < this.param.length; i++){
          this.$store.dispatch('postApproval', this.param[i]).then((res) => {
            if(res.status == 'success') {
              this.$notify({
                title: 'Success',
                message: res.message,
                icon: "ti-check",
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'success',
              });
    
              this.$store.dispatch(`fetch${this.param[i].type}`, {noReload: false});
              this.closeModal();
            } else {
              this.$notify({
                title: 'Failed',
                message: res.message,
                icon: "ti-na",
                horizontalAlign: 'right',
                verticalAlign: 'top',
                type: 'danger',
              });
              this.showLoader = false;
            }
          });
        }
      } else {
        this.$store.dispatch('postApproval', this.param).then((res) => {
          if(res.status == 'success') {
            this.$notify({
              title: 'Success',
              message: res.message,
              icon: "ti-check",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            });
  
            this.$store.dispatch(`fetch${this.param.type}`, {noReload: false});
            this.closeModal();
          } else {
            this.$notify({
              title: 'Failed',
              message: res.message,
              icon: "ti-na",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'danger',
            });
            this.showLoader = false;
          }
        });
      }

    }
  },
  directives: {
    numeric: function(el) {
      el.addEventListener('keyup', () => {
        let regex = /^[0-9]*$/;
        if (!regex.test(el.value)) {
          el.value = el.value.slice(0, -1)
        }
      })
    }
  },
}