import convertCurrency from '../../../helper/convertToCurrency';
import moment from 'moment';

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, column, key) => {
  if (key) {
    return items.filter(item => toLower(item[column]).includes(toLower(key)))
  }

  return items;
}

export default {
  name: 'payment-list',
  data() {
    return {
      search: '',
      searched: [],
      column: 'loanNo',
      showModal: false,
      dataRow: {},
    }
  },
  computed: {
    dataPayment() {
      var arr = this.$store.state.dataPayment.payment;
      var idx = 1;

      for (let i = 0; i < arr.length; i++) {
        arr[i].no = idx;
        idx++;
      }
      
      return arr;
    }
  },
  created() {
    this.searched = this.dataPayment;
  },
  methods: {
    searchOnTable () {
      this.searched = searchByName(this.dataPayment, this.column, this.search);
    },
    clickedRow(item) {
      alert(JSON.stringify(item))
    },
  },
  filters: {
    formatDate: function(value) {
      if (value !== 0) {
        return moment.unix(value).format('DD/MM/YYYY HH:mm');
      } else {
        return '-';
      }
    },
    formatCurrency: function(value) {
      if(value){
          return `Rp ${convertCurrency(value)}`
      }
    },
  },
}