<template>
    <div>
        <div class="escrow-balance-wrapper">
            <EscrowBalanceCard />
        </div>
        <div class="table-wrapper">
            <div>
                <b-form inline>
                    <b-form-group class="mr-4 mb-4" label-for="input-loan-no">
                        <b-form-input
                            id="input-loan-no"
                            v-model="filterParams.loanNumber"
                            v-on:keyup.enter="getDatalist()"
                            placeholder="Loan No"
                        >
                        </b-form-input>
                    </b-form-group>

                    <b-form-group class="mr-4 mb-4" label-for="input-name">
                        <b-form-input
                            id="input-name"
                            v-model="filterParams.borrowerName"
                            v-on:keyup.enter="getDatalist()"
                            placeholder="Name"
                        >
                        </b-form-input>
                    </b-form-group>
                </b-form>
            </div>
            <div>
                <b-table
                    hover
                    selectable
                    :select-mode="selectMode"
                    :items="disbursementList"
                    :fields="fields"
                    @row-selected="onRowSelected"
                >
                    <template v-slot:cell(disburseAmount)="row">
                        <div v-if="disbursementList[row.index].disburseAmount > 0">
                            {{
                                disbursementList[row.index].disburseAmount
                                    | formatCurrency
                            }}
                        </div>
                        <div v-else>
                            Rp0
                        </div>
                    </template>
                    <template v-slot:cell(fullyFundedDate)="row">
                        <div>
                            {{
                                disbursementList[row.index].fullyFundedDate
                                    | formatDate
                            }}
                        </div>
                    </template>
                </b-table>
            </div>
            <div v-if="disbursementList.length === 0" class="d-flex">
                <span class="mx-auto"> Data Not Found </span>
            </div>
            <div class="pagination" v-if="disbursementList.length !== 0">
                <b-pagination-nav
                    v-model="currentPage"
                    :number-of-pages="totalPage"
                    base-url="#"
                    first-number
                    last-number
                    @change="getPaginationData()"
                ></b-pagination-nav>
            </div>
        </div>
    </div>
</template>

<script>
import convertCurrency from '../../../../helper/convertToCurrency';
import $store from '../../../../stores/store';
import { mapGetters } from 'vuex';
import moment from 'moment';
import EscrowBalanceCard from '../../components/EscrowBalanceCard/EscrowBalanceCard.vue';

export default {
    components: {
        EscrowBalanceCard
    },
    computed: {
        ...mapGetters(["disbursementList"])
    },
    created() {
        this.getDatalist();
    },
    data() {
        return {
            data: [],
            fields: [
                "loanNumber",
                "borrowerName",
                "disburseAmount",
                "fullyFundedDate"
            ],
            selectMode: "single",
            currentPage: 1,
            totalPage: 1,
            limit: 30,
            filterParams: {
                loanNumber: null,
                borrowerName: null
            }
        };
    },
    methods: {
        getDatalist() {
            this.currentPage = 1;
            const filterParams = {
                ...this.filterParams,
                page: this.currentPage,
                limit: this.limit
            };
            this.$store
                .dispatch("fetchDisbursementList", filterParams)
                .then(resp => {
                    this.totalPage = resp.data.pagination.total_page;
                });
        },
        getPaginationData() {
            const page = this.$route.hash.slice(1);
            const filterParams = {
                ...this.filterParams,
                page: page,
                limit: this.limit
            };
            this.$store.dispatch("fetchDisbursementList", filterParams);
        },
        onRowSelected(items) {
            this.$router.push({
                name: "disbursementDetail",
                query: {
                    disbursementId: items["0"].disbursementId
                }
            });
        }
    },
    filters: {
        formatDate: function(value) {
            if (value !== 0) {
                return moment.unix(value).format("DD MMM YYYY");
            } else {
                return "-";
            }
        },
        formatCurrency: function(value) {
            if (value) {
                return `Rp${convertCurrency(value)}`;
            }
        }
    }
};
</script>

<style scoped>
.table-wrapper {
    background-color: white;
    padding: 4rem;
}

.pagination {
    float: right;
}
</style>
