import ChangePinModal from '@/components/modal/changepinmodal/index';


export default {
    data() {
        return {
            activeNotifications: false,
            showChangeInputPin: false
        };
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
        currentUser() {
            return this.$store.state.currentUser;
        },
    },
    methods: {
        changePin() {
            this.showChangeInputPin = true;
        },
        onChangePinModalClose() {
            this.showChangeInputPin = !this.showChangeInputPin;
        },
        submitChangePin() {
            this.showChangeInputPin = !this.showChangeInputPin;
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        logout() {
            const logout = confirm('Are you sure to logout account?');

            if (logout) {
                localStorage.clear();
                location.reload();
                window.location.href = '/';
            }
        },
    },
    components: {
        ChangePinModal
    },
    filters: {
        initials: function (value) {
            var initials = value.match(/\b\w/g) || [];
            initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

            return initials;
        },
    }
};
