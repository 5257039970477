import api from '../config';

export default async function fetchJSON(path, options) {
  try {
    const response = await fetch(api + path, options);
    const code = response.status;
    const body = await response.json();
    return {
      code,
      ...body,
    };
  } catch (error) {
    throw error;
  }
}
