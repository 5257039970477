import Loader from 'vue-spinner/src/PulseLoader';

export default {
  data() {
    return {
      activeDisplay: 'login',
      password: '',
      username: '',
      isLoading: false,
      emailErrorText: '',
      passwordErrorText: '',
    };
  },
  created(){
    let currentUser;
    try {
      currentUser = JSON.parse(localStorage.vuex).currentUser.session;
    } catch (error) {

    }

    if(currentUser){
        this.$router.push('/payment-panel');
    }
  },
  methods: {
    login(e) {
      e.preventDefault();
      this.isLoading = true;
    //   if (this.verified) {
        const credentials = {
            email: this.username,
            password: this.password,
            admin: true,
            platform: window.navigator.platform,
            panel: 2,
            vendor: window.navigator.vendor,
            appVersion: window.navigator.appVersion,
        };
        console.log(credentials);

      this.$store.dispatch('doLogin', credentials).then((data) => {
          console.log(data)
        if (data.session) {
        console.log(data)
            this.$router.push('/payment-panel');
        }
        else {
          if (data.message === 'Email address is not valid') {
            this.emailErrorText = this.username ? data.message : 'Email can\'t be empty';
            this.passwordErrorText = this.password ? data.message : 'Password can\'t be empty';
          } else if (data.message === '') {
            this.passwordErrorText = 'Fail to Login, please check your connection.';
          } else {
            this.emailErrorText = '';
            this.passwordErrorText = this.password ? data.message : 'Password can\'t be empty';
          }
          this.isLoading = false;
        }
      });
    },
  },
  components: { Loader },
};
