<template>
    <div class="card escrow-balance-card-container">
        <div class="card-title">
            Cash Balance
        </div>
        <div v-if="finishLoad === false" class="card-balance">
            <pulse-loader color="#00bbd3" size="10px"></pulse-loader>
        </div>
        <div v-else class="card-balance">
            <div v-if="account_balance > 0">
                {{ account_balance | formatCurrency }}
            </div>
            <div v-else>
                <div v-if="account_balance === 0">
                    Rp0
                </div>
                <div v-else>
                    -
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import convertCurrency from '../../../../helper/convertToCurrency';
import api from '../../shared/service/config';
import $store from '../../../../stores/store';
import { mapGetters } from 'vuex';
import { username, password } from '../../shared/service/credentials';
import Axios from 'axios';
import PulseLoader from 'vue-spinner/src/PulseLoader';

export default {
    name: "EscrowBalanceCard",
    components: { PulseLoader },
    computed: {
        ...mapGetters(["userInformation"])
    },
    created() {
        this.getEscrowBalanceInitial();
        this.reloadGetEscrowBalance();
    },
    beforeDestroy(){
        clearInterval(this.polling);
    },
    data() {
        return {
            finishLoad: false,
            account_balance: null,
            data: [],
            polling: null
        };
    },
    methods: {
        getEscrowBalanceInitial() {
            this.getEscrowBalance();
        },
        reloadGetEscrowBalance(){
            this.polling = setInterval(()=>{
                this.getEscrowBalance();
            }, 300000);
        },
        getEscrowBalance() {
            const params = {
                clientRef: 0 //kancil escrow clientref
            };
            this.$store.dispatch("fetchUserInformation", params).then(resp => {
                if (resp.data) {
                    this.account_balance = resp.data.data.account_balance;
                    this.finishLoad = true;
                } else {
                    this.account_balance = null;
                    this.finishLoad = true;
                }
            });
        },
    },
    filters: {
        formatCurrency: function(value) {
            if (value) {
                return `Rp${convertCurrency(value)}`;
            }
        }
    }
};
</script>

<style scoped>
@import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap";
.escrow-balance-card-container {
    border-radius: 0px;
    box-shadow: 0px 0px 0px;
    height: 88px;
    padding: 20px 64px;
}
.card-title {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.0041em;
    color: #000000;
}
.card-balance {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.0041em;
    color: #00bbd3;
}
</style>
