<template>
    <div>
        <div class="row">
            <div class="col-sm-8">
                <legend>{{ remainingPaymentDetail.loanDetail.loanNumber }}</legend>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-12">
                        <card>
                            <template slot="header">
                                <h6 class="card-title">Remaining Payment Detail</h6>
                                <hr>
                            </template>
                            <div class="content">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Remaining Payment Amount</div>
                                            <label v-if="remainingPaymentDetail.remainingPaymentDetail.remainingPaymentAmount === 0" ><b> Rp0 </b></label>
                                            <label v-else-if="remainingPaymentDetail.remainingPaymentDetail.remainingPaymentAmount > 0" ><b> {{ remainingPaymentDetail.remainingPaymentDetail.remainingPaymentAmount | formatCurrency }} </b></label>
                                            <label v-else><b> - </b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Transfer Amount</div>
                                            <label v-if="remainingPaymentDetail.remainingPaymentDetail.remainingPaymentAmount === 0" ><b> Rp0 </b></label>
                                            <label v-else-if="remainingPaymentDetail.remainingPaymentDetail.remainingPaymentAmount > 0" ><b> {{ remainingPaymentDetail.remainingPaymentDetail.remainingPaymentAmount | formatCurrency }} </b></label>
                                            <label v-else><b> - </b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>

                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">

                    <div class="col-lg-12">
                        <card>
                            <template slot="header">
                                <h6 class="card-title">Borrower Detail</h6>
                            <hr>
                            </template>
                            <div class="content">
                                <div class="row">
                                    <div class="col-sm-6">
                                    <div class="form-group">
                                        <div class="small text-muted">Borrower Name</div>
                                            <label><b>{{ remainingPaymentDetail.borrowerDetail.borrowerName }}</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Bank Account Number</div>
                                            <label><b>{{ remainingPaymentDetail.borrowerDetail.bankAccountNumber }}</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Bank Account Name</div>
                                            <label><b>{{ remainingPaymentDetail.borrowerDetail.bankAccountName }}</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Bank Name</div>
                                            <label><b>{{ remainingPaymentDetail.borrowerDetail.bankName }}</b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>

                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">

                    <div class="col-lg-12">
                        <card>
                            <template slot="header">
                                <h6 class="card-title">Loan Detail</h6>
                            <hr>
                            </template>
                            <div class="content">
                                <div class="row">
                                    <div class="col-sm-6">
                                    <div class="form-group">
                                        <div class="small text-muted">Loan No</div>
                                            <label><b> {{ remainingPaymentDetail.loanDetail.loanNumber }} </b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Loan Type</div>
                                            <label><b> {{ remainingPaymentDetail.loanDetail.loanType }} </b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Loan Amount</div>
                                            <label v-if="remainingPaymentDetail.remainingPaymentDetail.loanAmount === 0" ><b> Rp0 </b></label>
                                            <label v-else-if="remainingPaymentDetail.remainingPaymentDetail.loanAmount > 0" ><b> {{ remainingPaymentDetail.remainingPaymentDetail.loanAmount | formatCurrency }} </b></label>
                                            <label v-else><b> - </b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Loan Tenure</div>
                                            <label v-if="remainingPaymentDetail.loanDetail.loanTenor === '-' && remainingPaymentDetail.loanDetail.loanTenorPeriod === '-' "><b> - </b></label>
                                            <label v-else><b> {{ remainingPaymentDetail.loanDetail.loanTenor }} {{ remainingPaymentDetail.loanDetail.loanTenorPeriod }} </b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>

                </div>
            </div>
        </div>


        <button class="float" id="approve" @click="showPinModal" :disabled="disableButton()">
            <span class="my-float">TRANSFER</span>
        </button>

        <pin-modal v-if="showInputPin" :close="onModalClose" :reqParam="reqParam" :param="pinParam" />
    </div>

</template>

<script>
import moment from 'moment';
import convertCurrency from '@/helper/convertToCurrency';
import $store from '../../../../stores/store';
import { mapGetters } from 'vuex';
import PinModal from '../../modal/pin-modal';

export default {
    components: { PinModal },
    computed: {
        ...mapGetters(['remainingPaymentDetail'])
    },
    created() {
        this.reqParam = {
            remainingPaymentId: this.$route.query.remainingPaymentId,
            type: 'remainingPayment'
        }
        const params = {
            remainingPaymentId: this.$route.query.remainingPaymentId,
        }
        $store.dispatch('fetchRemainingPaymentDetail', params);
    },
    data() {
        return {
            showInputPin: false,
            pinParam: [],
            reqParam: {},
        }
    },
    methods: {
        showPinModal() {
            this.showInputPin = !this.showInputPin;
        },
        onModalClose(){
            this.showInputPin = !this.showInputPin;
            // this.approveRepayment();
        },
        approveRepayment(){
            // this.$store.dispatch('postRepayment', this.$route.query.repaymentId);
        },
        disableButton(){
            var op = "Operation";
            var ittech = "IT Tech";

            if(
                JSON.parse(localStorage.getItem("session")).userRole === op ||
                JSON.parse(localStorage.getItem("session")).userRole === ittech){ //OP or IT Tech is not allowed to edit
                return true;
            }else{
                return false;
            }
        },
    },
    filters: {
        formatDate: function(value) {
            if (value !== 0) {
                return moment.unix(value).format('DD MMM YYYY')
            } else {
                return '-';
            }
        },
        formatCurrency: function(value) {
            if(value){
                return `Rp${convertCurrency(value)}`
            }
        },
    },
    props: [

    ]
}
</script>

<style scoped>

</style>
