import Loader from 'vue-spinner/src/PulseLoader';

export default {
    name: 'pinmodal',
    components: { Loader },
    data() {
        return {
            defaultPin: 123456,
            pin1: '',
            pin2: '',
            pin3: '',
            pin4: '',
            pin5: '',
            pin6: '',
            ok: true,
            errorMessage: '',
            showLoader: false,
        }
    },
    mounted() {
        this.$refs.pin1.focus();
    },
    props: {
        close: {
            type: Function,
        },
        param: {
            type: Array
        },
        reqParam: {
            type: Object,
        }
    },
    methods: {
        closeModal() {
            this.$props.close();
        },
        inputPin(e) {
            let regex = /^-?\d*\.?\d*$/;
            if (!regex.test(e.target.value)) {
                e.target.value = e.target.value.slice(0, -1);
            } else {
                if (e.inputType == "insertText" && this.pin6 == '') {
                    e.target.nextElementSibling.focus();

                } else if (e.inputType == "deleteContentBackward" && this.pin1 != '') {
                    e.target.previousElementSibling.focus();
                    this.ok = true;

                } else {
                    var pin = [this.pin1, this.pin2, this.pin3, this.pin4, this.pin5, this.pin6];

                    if (pin.length == '6') {
                        this.ok = false;
                        this.errorMessage = '';
                    }
                }
            }
        },
        checkPin(method) {
            if (!this.ok) {
                this.showLoader = true;
                this.ok = true;

                var pin = [this.pin1, this.pin2, this.pin3, this.pin4, this.pin5, this.pin6];
                pin = parseInt(pin.join("")).toString();
                this.$store.dispatch('doCheckPin', pin).then((res) => {
                    if (res.message == 'Success') {
                        this.showLoader = false;
                        this.ok = true;
                        setTimeout(() => this.approve(method), 500)
                        // this.approve();
                        // this.closeModal();
                    } else if (res.message == 'Session is not valid') {
                        this.showLoader = false;
                        this.ok = true;
                        localStorage.clear();
                        window.location.href = '/login';
                    } else if (res.error_description == 'Pin not Match') {
                        this.showLoader = false;
                        this.errorMessage = res.error_description;
                    } else {
                        this.showLoader = false;
                        let title = ""
                        if (res.message.length <= 0) {
                            title = "Please check your internet connection"
                        }
                        else {
                            title = "Failed"
                        }
                        this.ok = false;
                        this.$notify({
                            title: title,
                            message: res.message,
                            icon: "ti-check",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                        this.$refs.pin1.focus();
                        this.pin = [];
                        this.pin1 = '';
                        this.pin2 = '';
                        this.pin3 = '';
                        this.pin4 = '';
                        this.pin5 = '';
                        this.pin6 = '';
                    }
                })
            }

            // this.approve();
        },
        disburse() {
            this.showLoader = true;
            this.$store.dispatch('postDisbursement', this.param);
        },
        approve(paymentMethod) {
            this.showLoader = true;

            if (this.$props.reqParam.type === 'disbursement') {
                const user = JSON.parse(localStorage.getItem('vuex'));
                const params = {
                    disbursementId: this.$props.reqParam.disbursementId,
                    userFullname: user.currentUser.user.fullname,
                    paymentMethod: paymentMethod,
                }
                this.$store.dispatch('postDisbursement', params).then((res) => {
                    // console.log(res)
                    // console.log(res.status)
                    if (res.status === 200) {
                        this.$notify({
                            title: 'Success',
                            message: res.message,
                            icon: "ti-check",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.navigateToDisbursementList();
                        this.closeModal();
                    } else {
                        this.$notify({
                            title: 'Failed',
                            message: res.response.data.error_description,
                            icon: "ti-na",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                        this.showLoader = false;
                        this.closeModal();
                    }
                });
            } else if (this.$props.reqParam.type === 'repayment') {
                const params = {
                    repaymentId: this.$props.reqParam.repaymentId,
                    paymentMethod: paymentMethod,
                }
                this.$store.dispatch('postRepayment', params).then((res) => {
                    if (res.status === 200) {
                        this.$notify({
                            title: 'Success',
                            message: res.message,
                            icon: "ti-check",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.navigateToRepaymentList();
                        this.closeModal();
                    } else {
                        this.$notify({
                            title: 'Failed',
                            message: res.response.data.error_description,
                            icon: "ti-na",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                        this.showLoader = false;
                        this.closeModal();
                    }
                });
            } else if (this.$props.reqParam.type === 'remainingPayment') {
                const params = {
                    remainingPaymentId: this.$props.reqParam.remainingPaymentId,
                    paymentMethod: paymentMethod,
                }
                this.$store.dispatch('postRemainingPayment', params).then((res) => {
                    if (res.status === 200) {
                        this.$notify({
                            title: 'Success',
                            message: res.message,
                            icon: "ti-check",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.navigateToRemainingPaymentList();
                        this.closeModal();
                    } else {
                        this.$notify({
                            title: 'Failed',
                            message: res.response.data.error_description,
                            icon: "ti-na",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                        this.showLoader = false;
                        this.closeModal();
                    }
                });
            } else if (this.$props.reqParam.type === 'bulkDisburse') {
                const params = {
                    disbursementIds: this.$props.reqParam.disbursementIds,
                }
                this.$store.dispatch('postBulkDisbursement', params).then((res) => {
                    if (res.status === 200) {
                        this.$notify({
                            title: 'Success',
                            message: res.message,
                            icon: "ti-check",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.navigateToBulkDisburseList();
                        this.closeModal();
                    } else {
                        this.$notify({
                            title: 'Failed',
                            message: res.response.data.error_description,
                            icon: "ti-na",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                        this.showLoader = false;
                        this.closeModal();
                    }
                });
            }else if (this.$props.reqParam.type === 'bulkRepayment') {
                const params = {
                    id: this.$props.reqParam.repaymentIds,
                }
                this.$store.dispatch('postBulkRepayment', params).then((res) => {
                    if (res.status === 200) {
                        this.$notify({
                            title: 'Success',
                            message: res.message,
                            icon: "ti-check",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'success',
                        });
                        this.navigateToBulkRepaymentList();
                        this.closeModal();
                    } else {
                        this.$notify({
                            title: 'Failed',
                            message: res.response.data.error_description,
                            icon: "ti-na",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'danger',
                        });
                        this.showLoader = false;
                        this.closeModal();
                    }
                });
            }
        },
        navigateToDisbursementList() {
            this.$router.push('/payment-panel/disbursement');
        },
        navigateToRepaymentList() {
            this.$router.push('/payment-panel/repayment');
        },
        navigateToRemainingPaymentList() {
            this.$router.push('/payment-panel/remaining-payment');
        },
        navigateToBulkDisburseList() {
            this.$router.push('/payment-panel/bulk-disbursement');
        },
        navigateToBulkRepaymentList() {
            this.$router.push('/payment-panel/bulk-repayment');
        },
    },
    directives: {
        numeric: function (el) {
            el.addEventListener('keyup', () => {
                let regex = /^[0-9]*$/;
                if (!regex.test(el.value)) {
                    el.value = el.value.slice(0, -1)
                }
            })
        }
    },
}
