import convertCurrency from '../../../helper/convertToCurrency';
import DisbursementDetail from '../detail/index';
import Loader from 'vue-spinner/src/PulseLoader';

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, column, key) => {
  if (key) {
    return items.filter(item => toLower(item[column]).includes(toLower(key)))
  }

  return items
}

export default {
  name: 'disbursement-list',
  components: { DisbursementDetail, Loader },
  data(){
    return {
      search: '',
      searched: [],
      column: 'loanNo',
      showModal: false,
      showDetail: false,
      showLoader: false,
      dataRow: {},
    }
  },
  computed: {
    dataDisbursement() {
      var arr = this.$store.state.dataDisbursement.disbursement;
      var idx = 1;

      for (let i = 0; i < arr.length; i++) {
        arr[i].no = idx;
        idx++;
      }
      
      return arr;
    },
  },
  created() {
    this.searched = this.dataDisbursement;
  },
  methods:{
    searchOnTable() {
      this.searched = searchByName(this.dataDisbursement, this.column, this.search);
    },
    clickedRow(item) {
      this.showLoader = true;
      this.$store.dispatch('fetchDisbursement', {noReload: true, param: item}).then((res) => {
        if(res.code == 200){
          this.showDetail = !this.showDetail;
          this.dataRow = {
            title: item.loanNo + ' - ' + item.invoiceNo,
            item: item,
          };
          this.showLoader = false;
        } else {
          this.$notify({
            title: 'Failed',
            message: res.message,
            icon: "ti-na",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
          });
          this.showLoader = false;
        }
      });

      // var arrayList = Object.entries(item).map(([key, value]) => ({key,value}));
    },
    onDetailClose(){
      this.$store.dispatch('fetchDisbursement', {noReload: false});
    },
  },
  filters: {
    formatDate: function(value) {
      if (value !== 0) {
        return moment.unix(value).format('DD/MM/YYYY')
      } else {
        return '-';
      }
    },
    formatCurrency: function(value) {
      if(value){
          return `Rp ${convertCurrency(value)}`
      }
    },
  },
}