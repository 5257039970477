<template>
    <div>
        <div class="row">
            <div class="col-sm-8">
                <legend>{{ repayment.loanDetail.loanNumber }}</legend>
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-12">
                        <card>
                            <template slot="header">
                                <h6 class="card-title">Repayment Detail</h6>
                                <hr/>
                            </template>
                            <div class="content">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Borrower Interest Percentage
                                            </div>
                                            <label
                                            ><b>
                                                {{
                                                `${(
                                                repayment
                                                .repaymentDetail
                                                .borrowerInterestPercentage *
                                                100
                                                ).toFixed(3)} %`
                                                }}
                                            </b></label
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Lender Interest Amount
                                            </div>
                                            <label
                                                v-if="
                                                    repayment.repaymentDetail
                                                        .lenderInterestAmount >
                                                        0
                                                "
                                            ><b>
                                                {{
                                                repayment
                                                .repaymentDetail
                                                .lenderInterestAmount
                                                | formatCurrency
                                                }}</b
                                            ></label
                                            >
                                            <label
                                                v-else-if="
                                                    repayment.repaymentDetail
                                                        .borrowerInterestAmount ===
                                                        0
                                                "
                                            ><b> Rp0 </b></label
                                            >
                                            <label v-else><b> - </b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Lender Interest Percentage
                                            </div>
                                            <label
                                            ><b>
                                                {{
                                                `${(
                                                repayment
                                                .repaymentDetail
                                                .lenderInterestPercentage *
                                                100
                                                ).toFixed(3)} %`
                                                }}
                                            </b></label
                                            >
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Borrower Interest Amount</div>
                                            <label><b>132000</b></label>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Late Days
                                            </div>
                                            <label
                                            ><b>
                                                {{
                                                repayment
                                                .repaymentDetail
                                                .lateDays
                                                }}
                                            </b></label
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Penalty Amount
                                            </div>
                                            <label
                                                v-if="
                                                    repayment.repaymentDetail
                                                        .penaltyAmount > 0
                                                "
                                            ><b>
                                                {{
                                                repayment
                                                .repaymentDetail
                                                .penaltyAmount
                                                | formatCurrency
                                                }}</b
                                            ></label
                                            >
                                            <label
                                                v-else-if="
                                                    repayment.repaymentDetail
                                                        .penaltyAmount === 0
                                                "
                                            ><b> Rp0 </b></label
                                            >
                                            <label v-else><b> - </b></label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Repayment Amount</div>
                                            <label><b> {{ repayment.repaymentDetail.lenderInterestPercentage | formatCurrency }} </b></label>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Payment Status
                                            </div>
                                            <label
                                            ><b>
                                                {{
                                                repayment
                                                .repaymentDetail
                                                .repaymentStatusName
                                                }}
                                            </b></label
                                            >
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Tax Amount
                                            </div>
                                            <label
                                                v-if="
                                                    repayment.repaymentDetail
                                                        .taxAmount > 0
                                                "
                                            ><b>
                                                {{
                                                repayment
                                                .repaymentDetail
                                                .taxAmount
                                                | formatCurrency
                                                }}</b
                                            ></label
                                            >
                                            <label
                                                v-else-if="
                                                    repayment.repaymentDetail
                                                        .taxAmount === 0
                                                "
                                            ><b> Rp0 </b></label
                                            >
                                            <label v-else><b> - </b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-12">
                        <card>
                            <template slot="header">
                                <h6 class="card-title">Loan Detail</h6>
                                <hr/>
                            </template>
                            <div class="content">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Loan No
                                            </div>
                                            <label
                                            ><b>
                                                {{
                                                repayment.loanDetail
                                                .loanNumber
                                                }}
                                            </b></label
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Loan Type
                                            </div>
                                            <label
                                            ><b>
                                                {{
                                                repayment.loanDetail
                                                .loanType
                                                }}
                                            </b></label
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Loan Amount
                                            </div>
                                            <label
                                                v-if="
                                                    repayment.loanDetail
                                                        .loanAmount > 0
                                                "
                                            ><b>
                                                {{
                                                repayment.loanDetail
                                                .loanAmount
                                                | formatCurrency
                                                }}
                                            </b></label
                                            >
                                            <label
                                                v-else-if="
                                                    repayment.loanDetail
                                                        .loanAmount === 0
                                                "
                                            ><b> Rp0 </b></label
                                            >
                                            <label v-else><b> - </b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">
                                                Loan Tenure
                                            </div>
                                            <label
                                                v-if="
                                                    repayment.loanDetail
                                                        .loanTenor === '-' &&
                                                        repayment.loanDetail
                                                            .loanTenorPeriod ===
                                                            '-'
                                                "
                                            ><b> - </b></label
                                            >
                                            <label v-else
                                            ><b>
                                                {{
                                                repayment.loanDetail
                                                .loanTenor
                                                }}
                                                {{
                                                repayment.loanDetail
                                                .loanTenorPeriod
                                                }}
                                            </b></label
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <card>
                    <template slot="header">
                        <h6 class="card-title">Lender Detail</h6>
                        <hr/>
                    </template>
                    <b-table
                        hover
                        selectable
                        :items="repayment.lenderDetail"
                        :fields="fields"
                        show-empty
                    >
                        <template v-slot:cell(investedAmount)="row">
                            <div v-if="repayment.lenderDetail[row.index]
                                .principalAmount > 0">
                                {{
                                repayment.lenderDetail[row.index]
                                .principalAmount | formatCurrency
                                }}
                            </div>
                            <div v-else>
                                Rp0
                            </div>
                        </template>
                        <template v-slot:cell(interestPercentage)="row">
                            <div>
                                {{
                                `${(
                                repayment.lenderDetail[row.index]
                                .interestPercentage * 100
                                ).toFixed(3)} %`
                                }}
                            </div>
                        </template>
                        <template v-slot:cell(interestAmount)="row">
                            <div v-if="repayment.lenderDetail[row.index]
                                .interestAmount > 0">
                                {{
                                repayment.lenderDetail[row.index]
                                .interestAmount | formatCurrency
                                }}
                            </div>
                            <div v-else>
                                Rp0
                            </div>
                        </template>

                        <template v-slot:cell(taxAmount)="row">
                            <div v-if="repayment.lenderDetail[row.index]
                                .taxAmount > 0">
                                {{
                                repayment.lenderDetail[row.index]
                                .taxAmount | formatCurrency
                                }}
                            </div>

                            <div v-else>
                                Rp0
                            </div>
                        </template>

                        <template v-slot:cell(repaymentAmount)="row">
                            <div v-if="repayment.lenderDetail[row.index]
                                .repaymentAmount > 0">
                                {{
                                repayment.lenderDetail[row.index]
                                .repaymentAmount | formatCurrency
                                }}
                            </div>
                            <div v-else>
                                Rp0
                            </div>
                        </template>
                        <template #empty="scope">
                            <h4 style="text-align: center;">Data Not Found</h4>
                        </template>
                    </b-table>
                </card>
            </div>
        </div>

        <button
            class="float"
            id="approve"
            @click="showPinModal"
            :disabled="disableButton()"
        >
            <span class="my-float">APPROVE</span>
        </button>

        <pin-modal
            v-if="showInputPin"
            :close="onModalClose"
            :reqParam="reqParam"
            :param="pinParam"
        />
    </div>
</template>

<script>
    import moment from "moment";
    import convertCurrency from "@/helper/convertToCurrency";
    import $store from "../../../../stores/store";
    import {mapGetters} from "vuex";
    import PinModal from "../../modal/pin-modal";

    export default {
        components: {PinModal},
        computed: {
            ...mapGetters(["repayment"])
        },
        created() {
            this.reqParam = {
                repaymentId: this.$route.query.repaymentId,
                type: "repayment"
            };
            const params = {
                repaymentId: this.$route.query.repaymentId
            };
            $store.dispatch("fetchRepaymentDetail", params).then(resp => {
                if(resp.status === 401) {
                    this.$notify({
                        title: 'Warning',
                        message: 'Sesi Anda sudah berakhir, silahkan login kembali',
                        icon: "ti-info-alt",
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'warning',
                    })

                    setTimeout(() => {
                        localStorage.clear();
                        location.reload();
                        window.location.href = '/';
                    }, 2000)
                }
            });
        },
        data() {
            return {
                showInputPin: false,
                pinParam: [],
                reqParam: {},
                fields: [
                    "lenderName",
                    "investedAmount",
                    "interestPercentage",
                    "interestAmount",
                    "taxAmount",
                    "repaymentAmount"
                ]
            };
        },
        methods: {
            showPinModal() {
                this.showInputPin = !this.showInputPin;
            },
            onModalClose() {
                this.showInputPin = !this.showInputPin;
                // this.approveRepayment();
            },
            approveRepayment() {
                this.$store.dispatch(
                    "postRepayment",
                    this.$route.query.repaymentId
                ).then(resp => {
                    if(resp.status === 401) {
                        this.$notify({
                            title: 'Warning',
                            message: 'Sesi Anda sudah berakhir, silahkan login kembali',
                            icon: "ti-info-alt",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'warning',
                        })

                        setTimeout(() => {
                            localStorage.clear();
                            location.reload();
                            window.location.href = '/';
                        }, 2000)
                    }
                });
            },
            disableButton() {
                var op = "Operation";
                var ittech = "IT Tech";

                if (
                    JSON.parse(localStorage.getItem("session")).userRole === op ||
                    JSON.parse(localStorage.getItem("session")).userRole === ittech
                ) {
                    //OP or IT Tech is not allowed to edit
                    return true;
                } else {
                    return false;
                }
            }
        },
        filters: {
            formatDate: function (value) {
                if (value !== 0) {
                    return moment.unix(value).format("DD MMM YYYY");
                } else {
                    return "-";
                }
            },
            formatCurrency: function (value) {
                if (value) {
                    return `Rp${convertCurrency(value)}`;
                }
            }
        },
        props: []
    };
</script>

<style scoped></style>
