export default function numberWithCommas(x) {
  if (x > 0) {
    return Number.parseInt(x, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return 'Rp 0';
}
export function deconvertCurrency(commaSeparatedNum) {
  if (commaSeparatedNum) {
    return Number.parseInt(commaSeparatedNum.split(',').join(''), 10);
  }
  return '';
}
