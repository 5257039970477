import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import SHA256 from 'sha256';

import fetchJSON from '../helper/fetchJSON';
import postJSON from '../helper/postJSON';
import postPin from '../helper/postPin';

import PaymentPanelStore from '../module/payment-panel/shared/service/PaymentPanelStore.store';
import { loanPublisherUsername, loanPublisherPassword } from '../module/payment-panel/shared/service/credentials';
import postLoginJSON from '../helper/postLoginJSON';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        currentUser: {},
        dataDisbursement: {},
        dataDisbursementDetail: {},
        dataPayment: {},
        dataRepayment: {},
        dataRepaymentDetail: {},
        dataTopup: {},
        dataWithdrawal: {},
        dataCount: {},
    },
    modules: {
        paymentPanelStoreModule: PaymentPanelStore
    },
    mutations: {
        cleanStorage(state) {
            state.currentUser = {};
            state.dataDisbursement = {};
            state.dataDisbursementDetail = {};
            state.dataPayment = {};
            state.dataRepayment = {};
            state.dataRepaymentDetail = {};
            state.dataTopup = {};
            state.dataWithdrawal = {};
            state.dataCount = {};
        },
        updateCurrentUser(state, data) {
            state.currentUser = data;
        },
        updateDataDisbursement(state, data) {
            state.dataDisbursement.disbursement = data.loan;
            state.dataDisbursement.isLoading = false;
        },
        updateDetailDisbursement(state, data) {
            state.dataDisbursementDetail.disbursement = data;
            state.dataDisbursementDetail.isLoading = false;
        },
        updateDataPayment(state, data) {
            state.dataPayment.payment = data.loan;
            state.dataPayment.isLoading = false;
        },
        updateDataRepayment(state, data) {
            state.dataRepayment.repayment = data.loan;
            state.dataRepayment.isLoading = false;
        },
        updateDetailRepayment(state, data) {
            state.dataRepaymentDetail.repayment = data;
            state.dataRepaymentDetail.isLoading = false;
        },
        updateDataTopup(state, data) {
            state.dataTopup.topup = data.transaction;
            state.dataTopup.isLoading = false;
        },
        updateDataWithdrawal(state, data) {
            state.dataWithdrawal.withdrawal = data.transaction;
            state.dataWithdrawal.isLoading = false;
        },
        updateDataCount(state, data) {
            state.dataCount.count = data;
        },
        setLoading(state, { isLoading, key, error }) {
            state[key] = {
                ...state[key],
                isLoading,
                error,
            };
        },
    },
    actions: {
        async doLogin({ commit }, credentials) {
            const param = {
                email: credentials.email,
                password: credentials.password,
            };

            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Basic " + window.btoa(loanPublisherUsername + ":" + loanPublisherPassword)
                },
                method: 'POST',
                body: JSON.stringify(credentials),
                // body: JSON.stringify(param),
            };
            let errorMessage = '';

            try {
                const { status, data, message, code } = await postLoginJSON('/login-admin', options, param);
                if (message === 'Success') {
                    commit('updateCurrentUser', data);
                    if (data.session) {
                        const roles = data.user.role;
                        const session = {
                            fullname: data.user.fullname,
                            userId: data.user.userId,
                            token: data.token,
                            session: data.session,
                            userRole: data.role,
                            role: roles,
                        };
                        localStorage.setItem('session', JSON.stringify(session));
                        // const count = await fetchJSON('/reload', configAPI);
                        // commit('updateDataCount', count.data);
                    }
                    return data;
                }

                errorMessage = message;
                throw new Error(message);

            } catch (error) {

            }

            return {
                message: errorMessage,
            };
        },

        // async fetchDisbursement({ commit, state }, {noReload, param}) {
        //   if (param == null && !noReload) commit('setLoading', { isLoading: true, key: 'dataDisbursement' });
        //
        //   const configAPI = {
        //     headers: {
        //       'Content-Type' : 'application/json',
        //       'Authorization': state.currentUser.session,
        //     },
        //   };
        //
        //   const url = param != null ? `/disbursement/${param.loanId}` : '/disbursement';
        //
        //   try {
        //     const { message, data, code } = await fetchJSON(url, configAPI);
        //     if (code === 401 || code === 500) {
        //       if (param != null) {
        //         commit('updateDetailDisbursement', {});
        //       } else {
        //         commit('updateDataDisbursement', {});
        //       }
        //
        //       return {code, message};
        //     } else if (code === 200) {
        //       if (param != null) {
        //         commit('updateDetailDisbursement', data);
        //       } else {
        //         commit('updateDataDisbursement', data);
        //       }
        //
        //       return { message, data, code };
        //     } else {
        //       if (param != null) {
        //         commit('updateDetailDisbursement', {});
        //       } else {
        //         commit('updateDataDisbursement', {});
        //       }
        //
        //       throw new Error(message);
        //     }
        //   } catch (error) {
        //     commit('setLoading', {
        //       isLoading: false,
        //       key: 'dataDisbursement',
        //       error: error.message === 'Failed to fetch' ? 'Failed to process your data. Please check your connection.' : error.message,
        //     });
        //   };
        // },
        // async fetchPayment({ commit, state }, reload) {
        //   if (!reload) commit('setLoading', { isLoading: true, key: 'dataPayment' });
        //
        //   const configAPI = {
        //     headers: {
        //       'Content-Type' : 'application/json',
        //       'Authorization': state.currentUser.session,
        //     },
        //   };
        //
        //   try {
        //     const { message, data, code } = await fetchJSON('/payment', configAPI);
        //     if (code === 401 || code === 500) {
        //       commit('updateDataPayment', {});
        //
        //       return { code, message };
        //     } else if (code === 200) {
        //       commit('updateDataPayment', data);
        //
        //       return { message, data, code };
        //     } else {
        //       commit('updateDataPayment', {});
        //
        //       throw new Error(message);
        //     }
        //   } catch (error) {
        //     commit('setLoading', {
        //       isLoading: false,
        //       key: 'dataPayment',
        //       error: error.message === 'Failed to fetch' ? 'Failed to process your data. Please check your connection.' : error.message,
        //     });
        //   };
        // },
        // async fetchRepayment({ commit, state }, {noReload, param}) {
        //   if (param == null && !noReload) commit('setLoading', { isLoading: true, key: 'dataRepayment' });
        //
        //   const configAPI = {
        //     headers: {
        //       'Content-Type' : 'application/json',
        //       'Authorization': state.currentUser.session,
        //     },
        //   };
        //
        //   const url = param != null ? `/repayment/${param.loanId}` : '/repayment';
        //
        //   try {
        //     const { message, data, code } = await fetchJSON(url, configAPI);
        //
        //     if (code === 401 || code === 500) {
        //       if (param != null) {
        //         commit('updateDetailRepayment', {});
        //       } else {
        //         commit('updateDataRepayment', {});
        //       }
        //
        //       return { code, message };
        //     } else if (code === 200) {
        //       if (param != null) {
        //         commit('updateDetailRepayment', data);
        //       } else {
        //         commit('updateDataRepayment', data);
        //       }
        //
        //       return { message, data, code };
        //     } else {
        //       if (param != null) {
        //         commit('updateDetailRepayment', {});
        //       } else {
        //         commit('updateDataRepayment', {});
        //       }
        //
        //       throw new Error(message);
        //     }
        //   } catch (error) {
        //     commit('setLoading', {
        //       isLoading: false,
        //       key: 'dataRepayment',
        //       error: error,
        //     });
        //   };
        // },
        async fetchTopup({ commit, state }, reload) {
            if (!reload) commit('setLoading', { isLoading: true, key: 'dataTopup' });

            const configAPI = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': state.currentUser.session,
                },
            };

            try {
                const { message, data, code } = await fetchJSON('/topup', configAPI);

                if (code === 401 || code === 500) {
                    commit('updateDataTopup', {});

                    return { code, message };
                } else if (code === 200) {
                    commit('updateDataTopup', data);

                    return { message, data, code };
                } else {
                    commit('updateDataTopup', {});

                    throw new Error(message);
                }
            } catch (error) {
                commit('setLoading', {
                    isLoading: false,
                    key: 'updateDataTopup',
                    error: error,
                });
            };
        },
        async fetchWithdrawal({ commit, state }, reload) {
            if (!reload) commit('setLoading', { isLoading: true, key: 'dataWithdrawal' });

            const configAPI = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': state.currentUser.session,
                },
            };

            try {
                const { message, data, code } = await fetchJSON('/withdrawal', configAPI);

                if (code === 401 || code === 500) {
                    commit('updateDataWithdrawal', {});

                    return { code, message };
                } else if (code === 200) {
                    commit('updateDataWithdrawal', data);

                    return { message, data, code };
                } else {
                    commit('updateDataWithdrawal', {});

                    throw new Error(message);
                }
            } catch (error) {
                commit('setLoading', {
                    isLoading: false,
                    key: 'updateDataWithdrawal',
                    error: error,
                });
            };
        },
        async fetchCount({ commit, state }) {
            const configAPI = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': state.currentUser.session,
                },
            };

            try {
                const { message, data, code } = await fetchJSON('/reload', configAPI);
                if (code === 401 || code === 500) {
                    return { code, message };
                } else if (code === 200) {
                    commit('updateDataCount', data);

                    return data;
                } else {
                    throw new Error(message);
                }
            } catch (error) {
                commit('setLoading', {
                    isLoading: false,
                    key: 'dataCount',
                    error: error.message === 'Failed to fetch' ? 'Failed to process your data. Please check your connection.' : error.message,
                });
            };
        },
        async postApproval({ commit, state }, params) {
            const param = {
                "loanId": params.loanId,
                "sequence": params.sequence || 0,
            }
            if (params.status && params.method) {
                param.status = params.status.toLowerCase();
                param.method = params.method.toLowerCase();
            }

            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': state.currentUser.session,
                },
                method: 'POST',
                body: JSON.stringify(param),
            };

            let errorMessage = '';
            try {
                const { status, data, message, code } = await postJSON(params.action, options);
                if (status == 'success') {
                    return { status, data, message, code };
                }

                errorMessage = message;
                throw new Error(message);

            } catch (error) {

            }

            return {
                message: errorMessage,
            };
        },
        async doLogout({ commit, state }) {
            const param = {
                userId: state.currentUser.user.userId,
            };
            console.log(param)
            console.log(state.currentUser.user)

            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(loanPublisherUsername + ':' + loanPublisherPassword)
                },
                method: 'POST',
                body: JSON.stringify(param),
            };
            //   const configAPI = {
            //     headers: {
            //       'Content-Type' : 'application/json',
            //       'Authorization': state.currentUser.session,
            //     },
            //   };

            try {
                const { message, code } = await postLoginJSON('/logout-admin', options, param);
                if (message === 'Success') {
                    commit('cleanStorage');
                    localStorage.clear();
                    location.reload();
                    // this.$router.push('/');
                    window.location.href = '/';
                    return { code, message };
                } else if (code === 401 || code === 500) {
                    return { code, message };
                } else {
                    throw new Error(message);
                }
            } catch (error) {

            }
        },
        async doCheckPin({ commit, state }, pin) {
            const session = state.currentUser.session;

            const param = {
                pin: pin.toString(),
                userId: state.currentUser.user.userId,
            };

            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + window.btoa(loanPublisherUsername + ':' + loanPublisherPassword)
                },
                method: 'POST',
                body: JSON.stringify(param),
            };
            let errorMessage = '';
            let errorDescription = '';
            try {
                const { status, data, message, code, error_description } = await postPin('/match-pin-admin', options);

                if (message === 'Success') {
                    return { status, data, message, code };
                }

                errorMessage = message;
                errorDescription = error_description;

                if (message !== undefined || message !== '') {
                    throw new Error(message);
                }

                if (error_description !== undefined || error_description !== '') {
                    throw new Error(error_description);
                }

            } catch (error) {
            }

            return {
                message: errorMessage,
                error_description: errorDescription
            };
        },
        async changePin({ commit, state }, { oldPin, newPin }) { // eslint-disable-line
            const session = state.currentUser.session;
            let errorMessage = '';
            try {
                const pinHashed = {
                    oldPin: oldPin,
                    newPin: newPin,
                };
                const options = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': session,
                    },
                    method: 'POST',
                    body: JSON.stringify(pinHashed),
                };
                const { status, data, message, code } = await postPin('/admin-setup-pin', options);
                if (status == 'success') {
                    return { status, data, message, code };
                }

                errorMessage = message;
                throw new Error(message);

            } catch (error) {
            }

            return {
                message: errorMessage,
            };
            // try {
            //   const pinHashed = {
            //     oldPin: oldPin,
            //     newPin: newPin,
            //   };
            //   const { status, data, message, code, body } = await postPin('/admin-setup-pin', {
            //     headers: {
            //       Authorization: session,
            //       'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(pinHashed),
            //     method: 'POST',
            //   });
            //   const response = body;
            //   if (response.status === 'success') {
            //     return {
            //       status,
            //       data,
            //       message,
            //       code,
            //     };
            //   }
            //   throw new Error(response.message);
            // } catch (error) {
            //   return {
            //     flag: -1,
            //     error,
            //   };
            // }
        },
    },
    created() {
        if (localStorage.getItem('session')) {
            this.$router.push(`/${localStorage.getItem('authorizedPages')[0]}`);
        }
        if (this.noRobot) {
            this.verified = true;
        }
    },
    plugins: [persistedState()]
})
