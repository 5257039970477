import convertCurrency from '../../../helper/convertToCurrency';
import moment from 'moment';

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, column, key) => {
  if (key) {
    return items.filter(item => toLower(item[column]).includes(toLower(key)))
  }

  return items
}

export default {
  name: 'topup-list',
  data(){
    return {
      search: '',
      searched: [],
      column: 'lenderName',
      showModal: false,
      dataRow: {},
    }
  },
  computed: {
    dataTopup(){
      var arr = this.$store.state.dataTopup.topup;
      var idx = 1;

      arr.sort(function(old, latest){ return latest.trxDate - old.trxDate});

      for (let i = 0; i < arr.length; i++) {
        arr[i].no = idx;
        idx++;
      }
      
      return arr;
    }
  },
  created(){
    this.searched = this.dataTopup;
  },
  methods:{
    searchOnTable () {
      this.searched = searchByName(this.dataTopup, this.column, this.search);
    },
    clickedRow (item) {
      alert(JSON.stringify(item))
    },
  },
  filters: {
    formatDate: function(value) {
      if (value !== 0) {
        return moment.unix(value).format('DD/MM/YYYY HH:mm');
      } else {
        return '-';
      }
    },
    formatCurrency: function(value) {
      if(value){
          return `Rp ${convertCurrency(value)}`
      }

      return 'Rp 0';
    },
  },
}