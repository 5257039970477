import Layout from "@/layout/index";
// import Router from "vue-router";
// GeneralViews
import NotFound from "@/pages/notfound/NotFoundPage.vue";

// Admin pages
import Dashboard from "@/pages/dashboard/Dashboard.vue";

import PaymentPanelRouter from "../module/payment-panel/routes/PaymentPanelRouter.routes";

import Login from "@/pages/login/index";
import Disbursement from "@/pages/disbursement/index";
import Payment from "@/pages/payment/index";
import Repayment from "@/pages/repayment/index";
import Topup from "@/pages/topup/index";
import Withdrawal from "@/pages/withdrawal/index";

const route = [
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: "/login",
        component: Login
    },
    {
        path: "/kawan-payment",
        component: Layout,
        redirect: "/kawan-payment/disbursement",
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: Dashboard
            },
            {
                path: "disbursement",
                name: "disbursement",
                component: Disbursement
            },
            {
                path: "payment",
                name: "payment",
                component: Payment
            },
            {
                path: "repayment",
                name: "repayment",
                component: Repayment
            },
            {
                path: "topup",
                name: "topup",
                component: Topup
            },
            {
                path: "withdrawal",
                name: "withdrawal",
                component: Withdrawal
            }
        ]
    },
    { path: "*", component: NotFound }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

const routes = route.concat(PaymentPanelRouter);
export default routes;
