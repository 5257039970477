const domain = {
    prod: "apipay.kawancicil.co.id",
    staging: "stg-apipay.kawancicil.co.id",
    // login: "api-staging.kawancicil.co.id:8443/identity-provider-admin",
    login: "api.kawancicil.co.id:8443/identity-provider-admin",
    beta: "beta-apipay.kawancicil.co.id",
    develop: "dev-apipay.kawancicil.co.id"
  };
  const api = `https://${domain.prod}/api`;
  // export const login = `https://${domain.login}/api/v1`;
  export default api;
