<template>
  <div>
    <Loader v-if="isLoading" />
    <list v-else />
  </div>
</template>

<script>
  import $store from '../../stores/store';
  import Loader from '../../components/loader/index';
  import List from './list/index';

  export default {
    name: 'payment',
    components: { Loader, List },
    beforeRouteEnter: (to, from, next) => {
      next();
      $store.dispatch('fetchPayment').then((res) => {
        if (res.code === 401) {
          alert(`${res.message}, please re-login!`); // eslint-disable-line
          $store.commit('cleanStorage');
          next('/');
        }
      });
    },
    computed: {
      isLoading(){
        return this.$store.state.dataPayment.isLoading;
      },
    },
  };
</script>