<template>
    <div>
        <div class="escrow-balance-wrapper">
            <EscrowBalanceCard />
        </div>
        <div class="table-wrapper">
            <div>
                <b-form inline>
                    <b-form-group class="mr-4 mb-4" label-for="input-loan-no">
                        <b-form-input id="input-loan-no" v-model="filterParams.loanNumber" v-on:keyup.enter="getDatalist()"
                            placeholder="Loan No">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group class="mr-4 mb-4" label-for="input-name">
                        <b-form-input id="input-name" v-model="filterParams.borrowerName" v-on:keyup.enter="getDatalist()"
                            placeholder="Borrower Name">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group class="mb-4">
                        <div class="checkbox-field-wrapper">
                            <b-form-input type="text" id="checkbox-field" ref="checkboxField" v-model="checkboxField"
                                :readonly="!showCheckboxOptions" placeholder="Loan Type"
                                @click="toggleFilterCheckboxOptions"></b-form-input>
                        </div>
                        <b-form-checkbox-group v-model="selectedOptions" class="checkbox-options" v-if="showCheckboxOptions"
                            @change="onSelectedFilterCheckbox()" @click.stop>
                            <div class="checkbox-wrapper" v-if="checkboxOptions.length > 0">
                                <b-form-checkbox v-for="option in checkboxOptions" :key="option.id" :value="option.id">
                                    {{ option.name }}
                                </b-form-checkbox>
                            </div>
                            <div v-else>
                                Loading...
                            </div>
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-form>
            </div>
            <div>
                <div class="counter" v-if="selectedCount > 0">
                    <h3>{{ selectedCount }} Loan Terpilih</h3>
                    <button class="repayment-btn" id="repayment" @click="goToSummary()">
                        <span class="btn-text">Repayment</span>
                    </button>
                </div>
                <b-table v-if="updatedBulkRepaymentList.length > 0" :items="updatedBulkRepaymentList" :fields="tableFields"
                    hover responsive>
                    <template #head(checkBox)="row">
                        <b-form-checkbox v-model="selectAll" @change="selectAllRows()"></b-form-checkbox>
                    </template>
                    <template v-if="selectedCount === 0" #head(loanNumber)="row">
                        <div @click="$event => onClickShort('loanNumber', filterParams.orderByDirection || 'DESC')" class="justify-content">
                            <span>{{ row.label }} </span> <img class="cursor-pointer" v-if="row.field.isSort" src="../../../../assets/img/icon-sort.svg">
                        </div>
                    </template>
                    <template v-if="selectedCount === 0" #head(borrowerName)="row">
                        <div @click="$event => onClickShort('borrowerName', filterParams.orderByDirection || 'DESC')" class="justify-content">
                            <span>{{ row.label }} </span> <img class="cursor-pointer" v-if="row.field.isSort" src="../../../../assets/img/icon-sort.svg">
                        </div>
                    </template>
                    <template v-if="selectedCount === 0" #head(loanType)="row">
                        <span>{{ row.label }}</span>
                    </template>
                    <template v-if="selectedCount === 0" #head(repaymentAmount)="row">
                        <span>{{ row.label }}</span>
                    </template>
                    <template #cell(checkBox)="row">
                        <b-form-checkbox @change="validateSelectRow()" v-model="row.item.checked"></b-form-checkbox>
                    </template>
                    <template #cell(loanNumber)="row">
                        {{ row.item.loanNumber }}
                    </template>
                    <template #cell(borrowerName)="row">
                        {{ row.item.borrowerName }}
                    </template>
                    <template #cell(loanType)="row">
                        {{ row.item.loanProductName }}
                    </template>
                    <template #cell(repaymentAmount)="row">
                        {{ row.item.repaymentAmount | formatCurrency }}
                    </template>
                </b-table>
                <div v-else>
                    Data Not Found
                </div>
                <div class="pagination" v-if="bulkRepaymentList.length !== 0">
                    <b-pagination-nav v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number
                        last-number @change="getPaginationData()"></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import convertCurrency from "../../../../helper/convertToCurrency";
import $store from "../../../../stores/store";
import { mapGetters } from "vuex";
import moment from "moment";
import EscrowBalanceCard from "../../components/EscrowBalanceCard/EscrowBalanceCard.vue";

export default {
    components: {
        EscrowBalanceCard
    },
    computed: {
        ...mapGetters(["bulkRepaymentList"]),
        updatedBulkRepaymentList() {
            if (Array.isArray(this.bulkRepaymentList)) {
                return this.bulkRepaymentList.map(item => ({
                    ...item,
                    checked: false
                }));
            } else {
                return [];
            }
        }
    },
    mounted() {
        this.getLoanProduct();
    },
    created() {
        this.getDatalist();
    },
    data() {
        return {
            data: [],
            checkboxField: "",
            checkboxOptions: [],
            selectedOptions: [],
            selectedCount: 0,
            showCheckboxOptions: false,
            tableFields: [
                { key: "checkBox", label: "Checkbox" },
                { key: "loanNumber", label: "Loan Number", isSort: true },
                { key: "borrowerName", label: "Borrower Name", isSort: true },
                { key: "loanTypeName", label: "Loan Type" },
                { key: "repaymentAmount", label: "Repayment Amount" }
            ],
            selectAll: false,
            selectMode: "single",
            currentPage: 1,
            totalPage: 1,
            limit: 30,
            filterParams: {
                loanNumber: null,
                borrowerName: null,
                loanType: []
            }
        };
    },
    methods: {
        getLoanProduct() {
            this.$store.dispatch("fetchLoanProductList", {}).then(resp => {
                this.checkboxOptions = resp.data.data.map(item => ({
                    id: item.id,
                    name: item.name
                }));
            });
        },
        onSelectedFilterCheckbox() {
            this.$nextTick(() => {
                const selectedNames = this.checkboxOptions
                    .filter(option => this.selectedOptions.includes(option.id))
                    .map(option => option.name);
                this.checkboxField = selectedNames.join(", ");

                this.filterParams = {
                    ...this.filterParams,
                    loanType: this.selectedOptions
                };

                this.getDatalist();
            });
        },
        getDatalist() {
            this.selectedCount = 0;
            this.selectAll = false;
            this.currentPage = 1;
            const filterParams = {
                ...this.filterParams,
                page: this.currentPage,
                limit: this.limit
            };
            this.$store
                .dispatch("fetchBulkRepaymentList", filterParams)
                .then(resp => {
                    if (resp.response.status === 401) {
                        this.$notify({
                            title: 'Warning',
                            message: 'Sesi Anda sudah berakhir, silahkan login kembali',
                            icon: "ti-info-alt",
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'warning',
                        })

                        setTimeout(() => {
                            localStorage.clear();
                            location.reload();
                            window.location.href = '/';
                        }, 2000)
                    }
                    this.totalPage = resp.data.pagination.total_page;
                });
        },
        toggleFilterCheckboxOptions() {
            this.showCheckboxOptions = !this.showCheckboxOptions;
            if (this.showCheckboxOptions) {
                // Listen for click outside the checkbox field
                document.addEventListener("click", this.handleClickOutside);
            } else {
                // Remove the event listener when checkbox options are closed
                document.removeEventListener("click", this.handleClickOutside);
            }
        },
        handleClickOutside(event) {
            // Close the checkbox options if clicked outside the field
            if (
                !this.$refs.checkboxField ||
                (!this.$refs.checkboxField.$el.contains(event.target) &&
                    !event.target.classList.contains("checkbox-options") &&
                    !event.target.closest(".checkbox-options"))
            ) {
                this.showCheckboxOptions = false;
                document.removeEventListener("click", this.handleClickOutside);
            }
        },
        getPaginationData() {
            const page = this.$route.hash.slice(1);
            this.selectedCount = 0;
            this.selectAll = false;
            const filterParams = {
                ...this.filterParams,
                page: page,
                limit: this.limit
            };
            this.$store.dispatch("fetchBulkRepaymentList", filterParams);
        },
        selectAllRows() {
            this.$nextTick(() => {
                this.updatedBulkRepaymentList.forEach(item => {
                    item.checked = this.selectAll;
                    this.updateSelectedCount();
                });
            });
        },
        validateSelectRow() {
            this.$nextTick(() => {
                const isAllSelected = this.updatedBulkRepaymentList.every(
                    item => item.checked
                );
                this.selectAll = isAllSelected;
                this.updateSelectedCount();
            });
        },
        updateSelectedCount() {
            this.selectedCount = this.updatedBulkRepaymentList.filter(
                item => item.checked
            ).length;
        },
        goToSummary() {
            localStorage.removeItem("bulkRepaymentData");
            const checkedRows = this.updatedBulkRepaymentList
                .filter(row => row.checked)
                .map(row => row.repaymentId);

            localStorage.setItem(
                "bulkRepaymentData",
                JSON.stringify(checkedRows)
            );

            this.$router.push({
                name: "bulk-repayment-summary"
            });
        },
        onClickShort(name, orderBy) {
            this.filterParams= {
                ...this.filterParams,
                orderByValue: name,
                orderByDirection: orderBy === 'ASC' ? 'DESC' : 'ASC',
                page: this.currentPage,
                limit: this.limit
            }

            this.lenderList = [];

            if(this.filterParams.orderByValue) {
                this.$store.dispatch("fetchBulkRepaymentList", this.filterParams).then(resp => {
                    this.lenderList = resp.data;
                });
            }
        },
    },
    filters: {
        formatDate: function (value) {
            if (value !== 0) {
                return moment.unix(value).format("DD MMM YYYY");
            } else {
                return "-";
            }
        },
        formatCurrency: function (value) {
            if (value) {
                return `Rp${convertCurrency(value)}`;
            }
        }
    }
};
</script>

<style scoped>
.table-wrapper {
    background-color: white;
    padding: 4rem;
}

.selected-indicator {
    margin-left: 4px;
    color: green;
}

.pagination {
    float: right;
}

.checkbox-options {
    position: absolute;
    z-index: 100;
    width: 400px;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-top: 5px;
}

.checkbox-wrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: flex-start;
}

.checkbox-wrapper input[type="checkbox"] {
    margin: 5px 0;
}

.checkbox-field-wrapper {
    display: flex;
    align-items: center;
}

.selected-names {
    margin-left: 10px;
}

.counter {
    gap: 16px;
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
}

.repayment-btn {
    background-color: #2bde5e;
    width: 120px;
    height: 44px;
    border-radius: 14px;
    border: none;
}

.btn-text {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

input[readonly] {
    background-color: #fff;
    cursor: pointer;
}

.justify-content{
    display: flex;
    justify-content: space-between;
}

.cursor-pointer{
    cursor: pointer;
}
</style>
