import TopNavbar from "./topnavbar/index";
import MainContent from "./content/index";
import MobileMenu from "./MobileMenu.vue";
import $store from "../stores/store";

export default {
    beforeRouteEnter(to, from, next) {
        console.log("/******* RUNNING *******/");
        $store.dispatch("fetchCount");
        next();
    },
    components: {
        TopNavbar,
        MainContent,
        MobileMenu
    },
    computed: {
        count: {
            get() {
                return this.countItem;
            },
            set(v) {
                this.countItem = v;
            }
        }
    },
    data() {
        return {
            reloadCount: "",
            countItem: $store.state.dataCount.count
        };
    },
    mounted() {
        this.reload();
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        reload() {
            this.reloadCount = setInterval(() => {
                const route = this.$route.name;
                if (route) {
                    $store.dispatch("fetchCount").then(res => {
                        this.count = res;
                        // $store.dispatch(`fetch${this.capitalize(route)}`, {noReload: true});
                    });
                    // $store.dispatch(`fetch${this.capitalize(route)}`, {noReload: true});
                }
            }, 1000 * 60 * 30);
        },
        logout() {
            const logout = confirm("Are you sure to logout account?");

            if (logout) {
                this.$store.dispatch("doLogout").then(res => {
                    if (res.code === 200) {
                        this.$router.push("/");
                        this.$notify({
                            title: "Success!",
                            message: res.message,
                            icon: "ti-check",
                            horizontalAlign: "right",
                            verticalAlign: "top",
                            type: "success"
                        });
                    }
                });
            }
        },
        capitalize(val) {
            return val && val[0].toUpperCase() + val.slice(1);
        }
    }
};
