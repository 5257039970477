<template>
    <router-view></router-view>
</template>

<script>
export default {
    components: {},
    computed: {},
    created() {},
    data() {
        return {};
    },
    props: []
};
</script>

<style scoped></style>
