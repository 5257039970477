import moment from 'moment';
import convertCurrency from '@/helper/convertToCurrency';
import PinModal from '@/components/modal/pinmodal/index';

export default {
    name: 'disbursement-detail',
    components: {PinModal},
    data() {
        return {
            showInputPin: false,
            arrayStatus: ["Paid", "Overdue"],
            arrayMethod: ["Cash", "Transfer"],
            status: 'Paid',
            method: 'Transfer',
            param: {},
        }
    },
    computed: {
        detailDisbursement() {
            return this.$store.state.dataDisbursementDetail.disbursement;
        },
        title() {
            return this.data.title;
        }
    },
    methods: {
        showPinModal() {
            this.param = {
                'loanId': this.data.item.loanId,
                'sequence': this.data.item.disburstSeqNo,
                'status': this.status,
                'method': this.method,
                'action': '/disbursement',
                'type': 'Disbursement',
            }
            this.showInputPin = !this.showInputPin;
        },
        onModalClose() {
            this.showInputPin = !this.showInputPin;
        },
        backToList() {
            this.$props.close();
        },
        disableButton() {
            var op = "Operation";
            var ittech = "IT Tech";

            if (
                JSON.parse(localStorage.getItem("session")).userRole === op ||
                JSON.parse(localStorage.getItem("session")).userRole === ittech) { //OP or IT Tech is not allowed to edit
                return true;
            } else {
                return false;
            }
        }
    },
    props: {
        close: {
            type: Function,
        },
        data: Object,
    },
    filters: {
        formatDate: function (value) {
            if (value !== 0) {
                return moment.unix(value).format('DD/MM/YYYY');
            } else {
                return '-';
            }
        },
        formatCurrency: function (value) {
            if (value) {
                return `Rp ${convertCurrency(value)}`
            }

            return 'Rp 0';
        },
    },
}
