import Vue from "vue";
import App from "./App";
import router from "./router/index";
import BootstrapVue from 'bootstrap-vue';

import PaperDashboard from "./plugins/paperDashboard";
import "vue-notifyjs/themes/default.css";

import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

import store from './stores/store';
import convertToCurrency from './helper/convertToCurrency';

import moment from 'moment';

Vue.use(PaperDashboard);
Vue.use(BootstrapVue);
Vue.use(VueMaterial)

/* eslint-disable no-new */
new Vue({
  router,
  store,
  moment,
  convertToCurrency,
  render: h => h(App)
}).$mount("#app");
