import Loader from 'vue-spinner/src/PulseLoader';
import SHA256 from 'sha256';

  export default {
    name: 'pinmodal',
    components: { Loader },
    data() {
      return {
        oldPin: '',
        newPin: '',
        confirmPin: '',
        ok: true,
        errorMessage: '',
        showLoader: false,
      };
    },
    mounted() {
      this.$refs.oldPin.focus();
    },
    props: {
      close: {
        type: Function,
      },
      submitChange: {
        type: Function,
      }
    },
    computed:{
      language(){
        return localStorage.getItem('lang')
      }
    },
    methods: {
      closeModal() {
        this.$props.close();
      },
      inputOldPin(e) {
        let regex = /^[0-9]*$/;
        if (!regex.test(e.target.value)) {
          e.target.value = e.target.value.slice(0, -1);
        }
      },
      inputNewPin(e) {
        let regex = /^[0-9]*$/;
        if (!regex.test(e.target.value)) {
          e.target.value = e.target.value.slice(0, -1);
        } else {
          if ( this. oldPin === this.newPin){
            this.ok = true;
            this.errorMessage = 'Old Pin cannot be the same as New Pin'
          } else if (this.confirmPin === this.newPin && this.oldPin != this.newPin) {
              this.ok = false;
              this.errorMessage = '';
            } 
        }
      },
      inputConfirmPin(e) {
        let regex = /^[0-9]*$/;
        if (!regex.test(e.target.value)) {
          e.target.value = e.target.value.slice(0, -1);
        } else {
            if ( this. oldPin === this.newPin){
              this.ok = true;
              this.errorMessage = 'Old Pin cannot be the same as New Pin'
            }
            else if (this.confirmPin === this.newPin && this.oldPin != this.newPin) {
              this.ok = false;
              this.errorMessage = '';
            } else {
              this.ok = true;
              this.errorMessage = 'New Pin does not match Confirmation Pin'
            }
          }
      },
      approve() {
        this.showLoader = true;
        this.$store.dispatch('changePin',  {oldPin: SHA256(this.oldPin), newPin: SHA256(this.newPin)}).then((res) => {
          if(res.status == 'success') {
            this.$notify({
              title: 'Success',
              message: res.message,
              icon: "ti-check",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            });
  
            this.closeModal();
          } else {
            this.$notify({
              title: 'Failed',
              message: res.message,
              icon: "ti-na",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'danger',
            });
            this.showLoader = false;
          }
        });
      },
    },
    directives: {
      numeric(el) {
        el.addEventListener('keyup', () => {
          let regex = /^[0-9]*$/;
          if (!regex.test(el.value)) {
            el.value = el.value.slice(0, -1);
          }
        });
      },
    },
  };