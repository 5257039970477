import Layout from "@/layout/index";
// import NotFound from "@/pages/notfound/NotFoundPage.vue";
import DisbursementPanelList from "../pages/DisbursementPanel/DisbursementPanelList.vue";
import RepaymentPanelList from "../pages/RepaymentPanel/RepaymentPanelList.vue";
import DisbursementPanelDetail from "../pages/DisbursementPanel/DisbursementPanelDetail.vue";
import RepaymentPanelDetail from "../pages/RepaymentPanel/RepaymentPanelDetail.vue";
import RemainingPaymentList from "../pages/RemainingPaymentPanel/RemainingPaymentList.vue";
import RemainingPaymentDetail from "../pages/RemainingPaymentPanel/RemainingPaymentDetail.vue";
import BulkDisbursementList from "../pages/BulkDisbursmentPanel/BulkDisbursementList.vue";
import BulkDisbursementSummary from "../pages/BulkDisbursmentPanel/BulkDisbursementSummary.vue";
import BulkRepaymentList from "../pages/BulkRepaymentPanel/BulkRepaymentList.vue";
import BulkRepaymentSummary from "../pages/BulkRepaymentPanel/BulkRepaymentSummary.vue";

import Index from "../pages/Index.vue";

export default [
    {
        path: "/payment-panel",
        component: Layout,
        redirect: "/payment-panel/disbursement",
        children: [
            {
                path: "disbursement",
                name: "disbursement",
                component: Index,
                redirect: "/payment-panel/disbursement/disbursementList",
                children: [
                    {
                        path: "disbursementDetail",
                        name: "disbursementDetail",
                        component: DisbursementPanelDetail
                    },
                    {
                        path: "disbursementList",
                        name: "disbursementList",
                        component: DisbursementPanelList
                    }
                ]
            },
            {
                path: "repayment",
                name: "repayment",
                component: Index,
                redirect: "/payment-panel/repayment/repaymentList",
                children: [
                    {
                        path: "repaymentDetail",
                        name: "repaymentDetail",
                        component: RepaymentPanelDetail
                    },
                    {
                        path: "repaymentList",
                        name: "repaymentList",
                        component: RepaymentPanelList
                    }
                ]
            },
            {
                path: "remaining-payment",
                name: "remaining-payment",
                redirect: "/payment-panel/remaining-payment/remaining-payment-list",
                component: Index,
                children: [
                    {
                        path: "remaining-payment-detail",
                        name: "remaining-payment-detail",
                        component: RemainingPaymentDetail
                    },
                    {
                        path: "remaining-payment-list",
                        name: "remaining-payment-list",
                        component: RemainingPaymentList
                    }
                ]
            },
            {
                path: "bulk-disbursement",
                name: "bulk-disbursement",
                redirect: "/payment-panel/bulk-disbursement/bulk-disbursement-list",
                component: Index,
                children: [
                    {
                        path: "bulk-disbursement-list",
                        name: "bulk-disbursement-list",
                        component: BulkDisbursementList
                    },
                    {
                        path: "bulk-disbursement-summary",
                        name: "bulk-disbursement-summary",
                        component: BulkDisbursementSummary
                    },
                ]
            },
            {
                path: "bulk-repayment",
                name: "bulk-repayment",
                redirect: "/payment-panel/bulk-repayment/bulk-repayment-list",
                component: Index,
                children: [
                    {
                        path: "bulk-repayment-list",
                        name: "bulk-repayment-list",
                        component: BulkRepaymentList
                    },
                    {
                        path: "bulk-repayment-summary",
                        name: "bulk-repayment-summary",
                        component: BulkRepaymentSummary
                    },
                ]
            }
            // {
            //     path: "disbursementDetail",
            //     name: "disbursementDetail",
            //     component: DisbursementPanelDetail
            // },
            // {
            //     path: "repaymentDetail",
            //     name: "repaymentDetail",
            //     component: RepaymentPanelDetail
            // },
        ]
    }
];
