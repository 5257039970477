import convertCurrency from '../../../helper/convertToCurrency';
import RepaymentDetail from '../detail/index';
import Loader from 'vue-spinner/src/PulseLoader';
import PinModal from '@/components/modal/pinmodal/index';
import postJSON from '../../../helper/postJSON';


const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, column, key) => {
  if (key) {
    return items.filter(item => toLower(item[column]).includes(toLower(key)))
  }

  return items
}

export default {
  name: 'repayment-list',
  components: { RepaymentDetail, Loader, PinModal },
  data(){
    return {
      search: '',
      searched: [],
      column: 'loanNo',
      showModal: false,
      showDetail: false,
      showLoader: false,
      dataRow: {},
      selected: [],
      selectAll: false,
      showInputPin: false,
      param: {},
      paramArr: []
    }
  },
  computed: {
    dataRepayment() {
      var arr = this.$store.state.dataRepayment.repayment;
      var idx = 1;

      for (let i = 0; i < arr.length; i++) {
        arr[i].no = idx;
        idx++;
      }

      return arr;
    },
  },
  created() {
    this.searched = this.dataRepayment;
  },
  props: {
    close: {
      type: Function,
    }
  },
  methods:{
    select() {
			this.selected = [];
			if (!this.selectAll) {
				for (let i in this.item) {
					this.selected.push(this.items[i].loanId);
				}
			}
    },
    async doApprove1() {
      this.showInputPin = !this.showInputPin;
    },
    async doApprove() {
      for(let i = 0; i < this.selected.length ; i++){
        
        this.paramArr.push(
          this.param = {
            'loanId': this.selected[i].loanId,
            'sequence': this.selected[i].repaymentSeqNo,
            // 'status': this.status,
            // 'method': this.method,
            'action': '/repayment',
            'type': 'Repayment',
          }
        )
      }
      this.showInputPin = !this.showInputPin;
    },
    onModalClose(){
      this.showInputPin = !this.showInputPin;
    },
    backToList() {
      this.$props.close();
    },
    searchOnTable() {
      this.searched = searchByName(this.dataRepayment, this.column, this.search);
    },
    clickedRow(item) {
      this.showLoader = true;
      this.$store.dispatch('fetchRepayment', {noReload: true, param: item}).then((res) => {
        if(res.code == 200){
          this.showDetail = !this.showDetail;
          this.dataRow = {
            title: item.loanNo + ' - ' + item.invoiceNo,
            item: item,
          };
          this.showLoader = false;
        } else {
          this.$notify({
            title: 'Failed',
            message: res.message,
            icon: "ti-na",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
          });
          this.showLoader = false;
        }
      });
    },
    onDetailClose(){
      this.$store.dispatch('fetchRepayment', {noReload: false});
    },
  },
  filters: {
    formatDate: function(value) {
      if (value !== 0) {
        return moment.unix(value).format('DD/MM/YYYY HH:mm')
      } else {
        return '-';
      }
    },
    formatCurrency: function(value) {
      if(value){
          return `Rp ${convertCurrency(value)}`
      }
    },
  },
}
