<template>
    <div>
        <div class="escrow-balance-wrapper">
            <EscrowBalanceCard />
        </div>
        <div class="table-wrapper">
            <div>
                <b-form inline>
                    <b-form-group class="mr-4 mb-4" label-for="input-loan-no">
                        <b-form-input
                            id="input-loan-no"
                            v-model="filterParams.loanNumber"
                            v-on:keyup.enter="getDatalist()"
                            placeholder="Loan No"
                        >
                        </b-form-input>
                    </b-form-group>

                    <b-form-group class="mr-4 mb-4" label-for="input-name">
                        <b-form-input
                            id="input-name"
                            v-model="filterParams.borrowerName"
                            v-on:keyup.enter="getDatalist()"
                            placeholder="Borrower Name"
                        >
                        </b-form-input>
                    </b-form-group>

                    <b-form-group class="mr-4 mb-4" label-for="input-lender">
                        <b-form-input
                            id="input-lender"
                            v-model="filterParams.lenderName"
                            @input="getLenderName()"
                            placeholder="Lender Name"
                        ></b-form-input>
                        <div class="lender-wrapper" v-if="filterParams.lenderName">
                            <div class="lender-option" v-if="lenderList.length > 0">
                                <span class="lender" v-for="lender in lenderList" @click="selectLender(lender)">
                                {{ lender.lenderName }}
                                </span>
                            </div>
                            <div v-else>
                                <span>No Matching Lender</span>
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group class="mb-4">
                        <div class="checkbox-field-wrapper">
                            <b-form-input
                                type="text"
                                id="checkbox-field"
                                ref="checkboxField"
                                v-model="checkboxField"
                                :readonly="!showCheckboxOptions"
                                placeholder="Loan Type"
                                @click="toggleFilterCheckboxOptions"
                            ></b-form-input>
                        </div>
                        <b-form-checkbox-group
                            v-model="selectedOptions"
                            class="checkbox-options"
                            v-if="showCheckboxOptions"
                            @change="onSelectedFilterCheckbox()"
                            @click.stop
                        >
                            <div
                                class="checkbox-wrapper"
                                v-if="checkboxOptions.length > 0"
                            >
                                <b-form-checkbox
                                    v-for="option in checkboxOptions"
                                    :key="option.id"
                                    :value="option.id"
                                >
                                    {{ option.name }}
                                </b-form-checkbox>
                            </div>
                            <div v-else>
                                Loading...
                            </div>
                        </b-form-checkbox-group>
                    </b-form-group>
                </b-form>
            </div>
            <div class="search-lender-container mb-4" v-if="selectedLender.length > 0">
                <div class="search-label">
                    <span class="label">Search Lender: </span>
                </div>
                <div class="searched-lender">
                    <div
                        class="selected-lender"
                        v-for="lender in selectedLender"
                    >
                        <span> {{lender.lenderName}} </span>
                        <img class="remove-icon"
                            src="../../../../assets/img/icon-close-gray.svg"
                            alt="icon-close"
                            @click=removeSelectedLender(lender)
                        >
                    </div>
                </div>
            </div>
            <div>
                <div class="counter" v-if="selectedCount > 0">
                    <h3>{{ selectedCount }} Loan Terpilih</h3>
                    <button
                        class="disburse-btn"
                        id="disburse"
                        @click="goToSummary()"
                    >
                        <span class="btn-text">Disburse</span>
                    </button>
                </div>
                <b-table
                    v-if="updatedBulkDisbursementList.length > 0"
                    :items="updatedBulkDisbursementList"
                    :fields="tableFields"
                    hover
                    responsive
                >
                    <template #head(checkBox)="row">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="selectAllRows()"
                        ></b-form-checkbox>
                    </template>
                    <template
                        v-if="selectedCount === 0"
                        #head(loanNumber)="row"
                    >
                        <div @click="$event => onClickShort('loanNumber', filterParams.orderByDirection || 'DESC')" class="justify-content">
                            <span>{{ row.label }} </span> <img class="cursor-pointer" v-if="row.field.isSort" src="../../../../assets/img/icon-sort.svg">
                        </div>
                    </template>
                    <template
                        v-if="selectedCount === 0"
                        #head(borrowerName)="row"
                    >
                        <div @click="$event => onClickShort('borrowerName', filterParams.orderByDirection || 'DESC')" class="justify-content">
                            <span>{{ row.label }} </span> <img class="cursor-pointer" v-if="row.field.isSort" src="../../../../assets/img/icon-sort.svg">
                        </div>
                    </template>
                    <template
                        v-if="selectedCount === 0"
                        #head(lenderName)="row"
                    >
                        <span>{{ row.label }}</span>
                    </template>
                    <template v-if="selectedCount === 0" #head(loanType)="row">
                        <span>{{ row.label }}</span>
                    </template>
                    <template
                        v-if="selectedCount === 0"
                        #head(disburseAmount)="row"
                    >
                        <span>{{ row.label }}</span>
                    </template>
                    <template
                        v-if="selectedCount === 0"
                        #head(bankAccountInformation)="row"
                    >
                        <span>{{ row.label }}</span>
                    </template>
                    <template #cell(checkBox)="row">
                        <b-form-checkbox
                            @change="validateSelectRow()"
                            v-model="row.item.checked"
                        ></b-form-checkbox>
                    </template>
                    <template #cell(loanNumber)="row">
                        {{ row.item.loanNumber }}
                    </template>
                    <template #cell(borrowerName)="row">
                        {{ row.item.borrowerName }}
                    </template>
                    <template #cell(lenderName)="row">
                        <div class="ellipsis-tooltip">
                            {{ row.item.lenderName || '-'}}
                        </div>
                    </template>
                    <template #cell(loanType)="row">
                        {{ row.item.loanProductName }}
                    </template>
                    <template #cell(disburseAmount)="row">
                        {{ row.item.totalDisburseAmount | formatCurrency }}
                    </template>
                    <template #cell(bankAccountInformation)="row">
                        <div class="ellipsis-tooltip">
                            {{ row.item.accountNumber }} - {{ row.item.accountName }} - {{row.item.bankName}}
                        </div>
                    </template>
                </b-table>
                <div v-else>
                    Data Not Found
                </div>
                <div
                    class="pagination"
                    v-if="bulkDisbursementList.length !== 0"
                >
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        last-number
                        @change="getPaginationData()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import convertCurrency from "../../../../helper/convertToCurrency";
import $store from "../../../../stores/store";
import { mapGetters } from "vuex";
import moment from "moment";
import EscrowBalanceCard from "../../components/EscrowBalanceCard/EscrowBalanceCard.vue";

export default {
    components: {
        EscrowBalanceCard
    },
    computed: {
        ...mapGetters(["bulkDisbursementList"]),
        updatedBulkDisbursementList() {
            if (Array.isArray(this.bulkDisbursementList)) {
                return this.bulkDisbursementList.map(item => ({
                    ...item,
                    checked: false
                }));
            } else {
                return [];
            }
        }
    },
    mounted() {
        this.getLoanProduct();
    },
    created() {
        this.getDatalist();
    },
    data() {
        return {
            data: [],
            checkboxField: "",
            checkboxOptions: [],
            selectedOptions: [],
            selectedCount: 0,
            showCheckboxOptions: false,
            tableFields: [
                { key: "checkBox", label: "Checkbox", isSort: true },
                { key: "loanNumber", label: "Loan Number", isSort: true },
                { key: "borrowerName", label: "Borrower Name", isSort: true },
                { key: "lenderName", label: "Lender Name", isSort: false },
                { key: "loanProductName", label: "Loan Type", isSort: false },
                { key: "disburseAmount", label: "Disburse Amount", isSort: false },
                {
                    key: "bankAccountInformation",
                    label: "Bank Account Information",
                    isSort: false
                }
            ],
            selectAll: false,
            selectMode: "single",
            currentPage: 1,
            totalPage: 1,
            limit: 30,
            lenderList: [],
            selectedLender: [],
            filterParams: {
                loanNumber: null,
                borrowerName: null,
                lenderName: null,
                loanType: [],
                orderByValue: null,
                orderByDirection: 'DESC'
            }
        };
    },
    methods: {
        getLoanProduct() {
            this.$store.dispatch("fetchLoanProductList", {}).then(resp => {
                this.checkboxOptions = resp.data.data.map(item => ({
                    id: item.id,
                    name: item.name
                }));
            });
        },
        onSelectedFilterCheckbox() {
            this.$nextTick(() => {
                const selectedNames = this.checkboxOptions
                    .filter(option => this.selectedOptions.includes(option.id))
                    .map(option => option.name);
                this.checkboxField = selectedNames.join(", ");

                this.filterParams = {
                    ...this.filterParams,
                    loanType: this.selectedOptions
                };

                this.getDatalist();
            });
        },
        getDatalist() {
            this.selectedCount = 0;
            this.selectAll = false;
            this.currentPage = 1;
            let lenderId = this.selectedLender.map(item => item.secureId);
            if(lenderId.length) {
                lenderId = lenderId.join(',');
            }
            const filterParams = {
                ...this.filterParams,
                lenderId: lenderId,
                page: this.currentPage,
                limit: this.limit
            };
            this.$store
                .dispatch("fetchBulkDisbursementList", filterParams)
                .then(resp => {
                    this.totalPage = resp.data.pagination.total_page;
                });
        },
        getLenderName() {
            const params = {
                lenderName: this.filterParams.lenderName
            };

            if (params.lenderName.length > 2) {
                this.$store.dispatch("getLenderList", params).then(resp => {
                    this.lenderList = resp.data;
                });
            }else {
                this.lenderList = [];
            }
        },

        onClickShort(name, orderBy) {
            this.filterParams= {
                ...this.filterParams,
                orderByValue: name,
                orderByDirection: orderBy === 'ASC' ? 'DESC' : 'ASC',
                page: this.currentPage,
                limit: this.limit
            }

            this.lenderList = [];

            if(this.filterParams.orderByValue) {
                this.$store.dispatch("fetchBulkDisbursementList", this.filterParams).then(resp => {
                    this.lenderList = resp.data;
                });
            }
        },

        selectLender(lender) {
            if(this.selectedLender.length === 0) {
                this.selectedLender.push(lender);
                this.filterParams.lenderName = null;
                this.lenderList = [];
                this.getDatalist();
            } else {
                const existingLender = this.selectedLender.find(
                    (selected) => selected.secureId === lender.secureId
                );
                if (existingLender) {
                    alert('Lender already selected!');
                } else {
                    this.selectedLender.push(lender);
                    this.filterParams.lenderName = null;
                    this.lenderList = [];
                    this.getDatalist();
                }
            }
        },
        removeSelectedLender(lender) {
            const index = this.selectedLender.findIndex(x => x.secureId === lender.secureId);
            if (index !== -1) {
                this.selectedLender.splice(index, 1);
            }
            this.getDatalist();
        },
        toggleFilterCheckboxOptions() {
            this.showCheckboxOptions = !this.showCheckboxOptions;
            if (this.showCheckboxOptions) {
                // Listen for click outside the checkbox field
                document.addEventListener("click", this.handleClickOutside);
            } else {
                // Remove the event listener when checkbox options are closed
                document.removeEventListener("click", this.handleClickOutside);
            }
        },
        handleClickOutside(event) {
            // Close the checkbox options if clicked outside the field
            if (
                !this.$refs.checkboxField ||
                (!this.$refs.checkboxField.$el.contains(event.target) &&
                    !event.target.classList.contains("checkbox-options") &&
                    !event.target.closest(".checkbox-options"))
            ) {
                this.showCheckboxOptions = false;
                document.removeEventListener("click", this.handleClickOutside);
            }
        },
        getPaginationData() {
            const page = this.$route.hash.slice(1);
            this.selectedCount = 0;
            this.selectAll = false;
            const filterParams = {
                ...this.filterParams,
                page: page,
                limit: this.limit
            };
            this.$store.dispatch("fetchBulkDisbursementList", filterParams);
        },
        selectAllRows() {
            this.$nextTick(() => {
                this.updatedBulkDisbursementList.forEach(item => {
                    item.checked = this.selectAll;
                    this.updateSelectedCount();
                });
            });
        },
        validateSelectRow() {
            this.$nextTick(() => {
                const isAllSelected = this.updatedBulkDisbursementList.every(
                    item => item.checked
                );
                this.selectAll = isAllSelected;
                this.updateSelectedCount();
            });
        },
        updateSelectedCount() {
            this.selectedCount = this.updatedBulkDisbursementList.filter(
                item => item.checked
            ).length;
        },
        goToSummary() {
            localStorage.removeItem("bulkDisburseData");
            const checkedRows = this.updatedBulkDisbursementList
                .filter(row => row.checked)
                .map(row => row.disbursementId);

            localStorage.setItem(
                "bulkDisburseData",
                JSON.stringify(checkedRows)
            );

            this.$router.push({
                name: "bulk-disbursement-summary"
            });
        }
    },
    filters: {
        formatDate: function(value) {
            if (value !== 0) {
                return moment.unix(value).format("DD MMM YYYY");
            } else {
                return "-";
            }
        },
        formatCurrency: function(value) {
            if (value) {
                return `Rp${convertCurrency(value)}`;
            }
        }
    }
};
</script>

<style scoped>
.table-wrapper {
    background-color: white;
    padding: 4rem;
}
.selected-indicator {
    margin-left: 4px;
    color: green;
}
.pagination {
    float: right;
}

.lender-wrapper {
    position: absolute;
    z-index: 100;
    min-width: 250px;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-top: 5px;
}

.lender-option {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.lender {
    &:hover {
        background-color: #ced5e0;
        cursor: pointer;
    }
}

.checkbox-options {
    position: absolute;
    z-index: 100;
    width: 400px;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-top: 5px;
}

.checkbox-wrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: flex-start;
}

.checkbox-wrapper input[type="checkbox"] {
    margin: 5px 0;
}
.checkbox-field-wrapper {
    display: flex;
    align-items: center;
}

.selected-names {
    margin-left: 10px;
}

.counter {
    gap: 16px;
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
}

.disburse-btn {
    background-color: #2bde5e;
    width: 120px;
    height: 44px;
    border-radius: 14px;
    border: none;
}

.btn-text {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

input[readonly] {
    background-color: #fff;
    cursor: pointer;
}

.search-lender-container {
    display: flex;
    gap: 12px;
}

.search-label {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 37px;
    width: 100px;
}

.label {
    font-size: 12px;
    font-weight: 600;
    width: 100px;
}

.searched-lender {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.selected-lender {
    border: solid 1px #757575;
    border-radius: 14px;
    color: #757575;
    padding: 8px 24px;
    box-sizing: border-box;
    font-size: 16px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.remove-icon {
    height:24px;
    width:24px;
    cursor: pointer;
}

.ellipsis-tooltip {
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.b-table tbody tr:hover .ellipsis-tooltip {
    overflow: visible;
    white-space: normal;
    word-wrap: break-word;
}

.justify-content{
    display: flex;
    justify-content: space-between;
}

.cursor-pointer{
    cursor: pointer;
}

</style>
