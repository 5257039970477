<template>
    <div>
        <div class="row">
            <div class="col-sm-8">
                <legend>{{ disbursement.loanDetail.loanNumber }}</legend>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6">
                <div class="row">

                    <div class="col-lg-12">
                        <card>
                            <template slot="header">
                                <h6 class="card-title">Disburse Detail</h6>
                            <hr>
                            </template>
                            <div class="content">
                                <div class="row">
                                    <div class="col-sm-6">
                                    <div class="form-group">
                                        <div class="small text-muted">Loan Amount</div>
                                          <label v-if="disbursement.disburseDetail.loanAmount === 0"><b>Rp0</b></label>
                                          <label v-else-if="disbursement.disburseDetail.loanAmount > 0"><b>{{ disbursement.disburseDetail.loanAmount | formatCurrency }}</b></label>
                                          <label v-else><b>-</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Fees Amount</div>
                                            <label v-if="disbursement.disburseDetail.feesAmount === 0"><b>Rp0</b></label>
                                            <label v-else-if="disbursement.disburseDetail.feesAmount > 0"><b>{{ disbursement.disburseDetail.feesAmount | formatCurrency }}</b></label>
                                            <label v-else><b>-</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Interest Amount</div>
                                            <label v-if="disbursement.disburseDetail.interestAmount === 0"><b>Rp0</b></label>
                                            <label v-else-if="disbursement.disburseDetail.interestAmount > 0"><b>{{ disbursement.disburseDetail.interestAmount | formatCurrency }}</b></label>
                                            <label v-else><b>-</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Disbursement Amount</div>
                                            <label v-if="disbursement.disburseDetail.disbursementAmount === 0"><b>Rp0</b></label>
                                            <label v-else-if="disbursement.disburseDetail.disbursementAmount > 0"><b>{{ disbursement.disburseDetail.disbursementAmount | formatCurrency }}</b></label>
                                            <label v-else><b>-</b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>

                    <div class="col-lg-12">
                        <card>
                            <template slot="header">
                                <h6 class="card-title">Loan Detail</h6>
                            <hr>
                            </template>
                            <div class="content">
                                <div class="row">
                                    <div class="col-sm-6">
                                    <div class="form-group">
                                        <div class="small text-muted">Loan No</div>
                                            <label><b>{{ disbursement.loanDetail.loanNumber }}</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Loan Type</div>
                                            <label><b>{{ disbursement.loanDetail.loanType }}</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Loan Tenure</div>
                                            <label v-if="disbursement.loanDetail.loanTenor === '-' && disbursement.loanDetail.loanTenorPeriod==='-'"><b>-</b></label>
                                            <label v-else><b>{{ disbursement.loanDetail.loanTenor }} {{ disbursement.loanDetail.loanTenorPeriod }}</b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>

                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">

                    <div class="col-lg-12">
                        <card>
                            <template slot="header">
                                <h6 class="card-title">Borrower Detail</h6>
                            <hr>
                            </template>
                            <div class="content">
                                <div class="row">
                                    <div class="col-sm-6">
                                    <div class="form-group">
                                        <div class="small text-muted">Borrower Name</div>
                                            <label><b>{{ disbursement.borrowerDetail.borrowerName }}</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Bank Account Number</div>
                                            <label><b>{{ disbursement.borrowerDetail.bankAccountNumber }}</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Bank Account Name</div>
                                            <label><b>{{ disbursement.borrowerDetail.bankAccountName }}</b></label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <div class="small text-muted">Bank Name</div>
                                            <label><b>{{ disbursement.borrowerDetail.bankName }}</b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>

                </div>
            </div>
        </div>

        <button class="float" id="approve" @click="showPinModal" :disabled="disableButton()" :style="{ backgroundColor: disableButton() ? 'gray' : unset }">
            <span class="my-float" >DISBURSE</span>
        </button>

        <pin-modal v-if="showInputPin" :close="onModalClose" :reqParam="reqParam" :param="param" />
    </div>

</template>

<script>
import moment from 'moment';
import convertCurrency from '@/helper/convertToCurrency';
import $store from '../../../../stores/store';
import { mapGetters } from 'vuex';
import PinModal from '../../modal/pin-modal';

export default {
    components: { PinModal },
    computed: {
        ...mapGetters(['disbursement'])
    },
    created() {
        this.reqParam = {
            disbursementId: this.$route.query.disbursementId,
            type: 'disbursement'
        }
        const params = {
            disbursementId: this.$route.query.disbursementId,
        }

        $store.dispatch('fetchDisbursementDetail', params)
    },
    data() {
        return {
            showInputPin: false,
            param: [],
            reqParam: {}
        }
    },
    methods: {
        disburse(){
            this.$store.dispatch('postDisbursement', this.$route.query.disbursementId).then(resp => {
                if(resp.status === 200){
                    this.navigateToList();
                }
            });
        },
        showPinModal() {
            this.param = [];
            this.showInputPin = !this.showInputPin;
        },
        onModalClose(){
            this.showInputPin = !this.showInputPin;
            // this.disburse();
        },
        navigateToList(){
            this.$router.push('/payment-panel/disbursement');
        },
        disableButton(){
            var op = "Operation";
            var ittech = "IT Tech";

            if(
                JSON.parse(localStorage.getItem("session")).userRole === op ||
                JSON.parse(localStorage.getItem("session")).userRole === ittech ||
                !this.disbursement.disburseDetail.canDisburse
                ){ //OP or IT Tech is not allowed to edit
                return true;
            }else{
                return false;
            }
        },
    },
    filters: {
        formatDate: function(value) {
            if (value !== 0) {
                return moment.unix(value).format('DD MMM YYYY')
            } else {
                return '-';
            }
        },
        formatCurrency: function(value) {
            if(value){
                return `Rp${convertCurrency(value)}`
            }
        },
    },
    props: [

    ]
}
</script>

<style scoped>

</style>
