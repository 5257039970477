var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('legend',[_vm._v(_vm._s(_vm.repayment.loanDetail.loanNumber))])])]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('card',[_c('template',{slot:"header"},[_c('h6',{staticClass:"card-title"},[_vm._v("Repayment Detail")]),_c('hr')]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Borrower Interest Percentage\n                                        ")]),_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s((((
                                            _vm.repayment
                                            .repaymentDetail
                                            .borrowerInterestPercentage *
                                            100
                                            ).toFixed(3)) + " %"))+"\n                                        ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Lender Interest Amount\n                                        ")]),(
                                                _vm.repayment.repaymentDetail
                                                    .lenderInterestAmount >
                                                    0
                                            )?_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s(_vm._f("formatCurrency")(_vm.repayment
                                            .repaymentDetail
                                            .lenderInterestAmount)))])]):(
                                                _vm.repayment.repaymentDetail
                                                    .borrowerInterestAmount ===
                                                    0
                                            )?_c('label',[_c('b',[_vm._v(" Rp0 ")])]):_c('label',[_c('b',[_vm._v(" - ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Lender Interest Percentage\n                                        ")]),_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s((((
                                            _vm.repayment
                                            .repaymentDetail
                                            .lenderInterestPercentage *
                                            100
                                            ).toFixed(3)) + " %"))+"\n                                        ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Late Days\n                                        ")]),_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s(_vm.repayment
                                            .repaymentDetail
                                            .lateDays)+"\n                                        ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Penalty Amount\n                                        ")]),(
                                                _vm.repayment.repaymentDetail
                                                    .penaltyAmount > 0
                                            )?_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s(_vm._f("formatCurrency")(_vm.repayment
                                            .repaymentDetail
                                            .penaltyAmount)))])]):(
                                                _vm.repayment.repaymentDetail
                                                    .penaltyAmount === 0
                                            )?_c('label',[_c('b',[_vm._v(" Rp0 ")])]):_c('label',[_c('b',[_vm._v(" - ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Payment Status\n                                        ")]),_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s(_vm.repayment
                                            .repaymentDetail
                                            .repaymentStatusName)+"\n                                        ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Tax Amount\n                                        ")]),(
                                                _vm.repayment.repaymentDetail
                                                    .taxAmount > 0
                                            )?_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s(_vm._f("formatCurrency")(_vm.repayment
                                            .repaymentDetail
                                            .taxAmount)))])]):(
                                                _vm.repayment.repaymentDetail
                                                    .taxAmount === 0
                                            )?_c('label',[_c('b',[_vm._v(" Rp0 ")])]):_c('label',[_c('b',[_vm._v(" - ")])])])])])])],2)],1)])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('card',[_c('template',{slot:"header"},[_c('h6',{staticClass:"card-title"},[_vm._v("Loan Detail")]),_c('hr')]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Loan No\n                                        ")]),_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s(_vm.repayment.loanDetail
                                            .loanNumber)+"\n                                        ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Loan Type\n                                        ")]),_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s(_vm.repayment.loanDetail
                                            .loanType)+"\n                                        ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Loan Amount\n                                        ")]),(
                                                _vm.repayment.loanDetail
                                                    .loanAmount > 0
                                            )?_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s(_vm._f("formatCurrency")(_vm.repayment.loanDetail
                                            .loanAmount))+"\n                                        ")])]):(
                                                _vm.repayment.loanDetail
                                                    .loanAmount === 0
                                            )?_c('label',[_c('b',[_vm._v(" Rp0 ")])]):_c('label',[_c('b',[_vm._v(" - ")])])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"small text-muted"},[_vm._v("\n                                            Loan Tenure\n                                        ")]),(
                                                _vm.repayment.loanDetail
                                                    .loanTenor === '-' &&
                                                    _vm.repayment.loanDetail
                                                        .loanTenorPeriod ===
                                                        '-'
                                            )?_c('label',[_c('b',[_vm._v(" - ")])]):_c('label',[_c('b',[_vm._v("\n                                            "+_vm._s(_vm.repayment.loanDetail
                                            .loanTenor)+"\n                                            "+_vm._s(_vm.repayment.loanDetail
                                            .loanTenorPeriod)+"\n                                        ")])])])])])])],2)],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('card',[_c('template',{slot:"header"},[_c('h6',{staticClass:"card-title"},[_vm._v("Lender Detail")]),_c('hr')]),_c('b-table',{attrs:{"hover":"","selectable":"","items":_vm.repayment.lenderDetail,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(investedAmount)",fn:function(row){return [(_vm.repayment.lenderDetail[row.index]
                            .principalAmount > 0)?_c('div',[_vm._v("\n                            "+_vm._s(_vm._f("formatCurrency")(_vm.repayment.lenderDetail[row.index]
                            .principalAmount))+"\n                        ")]):_c('div',[_vm._v("\n                            Rp0\n                        ")])]}},{key:"cell(interestPercentage)",fn:function(row){return [_c('div',[_vm._v("\n                            "+_vm._s((((
                            _vm.repayment.lenderDetail[row.index]
                            .interestPercentage * 100
                            ).toFixed(3)) + " %"))+"\n                        ")])]}},{key:"cell(interestAmount)",fn:function(row){return [(_vm.repayment.lenderDetail[row.index]
                            .interestAmount > 0)?_c('div',[_vm._v("\n                            "+_vm._s(_vm._f("formatCurrency")(_vm.repayment.lenderDetail[row.index]
                            .interestAmount))+"\n                        ")]):_c('div',[_vm._v("\n                            Rp0\n                        ")])]}},{key:"cell(taxAmount)",fn:function(row){return [(_vm.repayment.lenderDetail[row.index]
                            .taxAmount > 0)?_c('div',[_vm._v("\n                            "+_vm._s(_vm._f("formatCurrency")(_vm.repayment.lenderDetail[row.index]
                            .taxAmount))+"\n                        ")]):_c('div',[_vm._v("\n                            Rp0\n                        ")])]}},{key:"cell(repaymentAmount)",fn:function(row){return [(_vm.repayment.lenderDetail[row.index]
                            .repaymentAmount > 0)?_c('div',[_vm._v("\n                            "+_vm._s(_vm._f("formatCurrency")(_vm.repayment.lenderDetail[row.index]
                            .repaymentAmount))+"\n                        ")]):_c('div',[_vm._v("\n                            Rp0\n                        ")])]}},{key:"empty",fn:function(scope){return [_c('h4',{staticStyle:{"text-align":"center"}},[_vm._v("Data Not Found")])]}}])})],2)],1)]),_c('button',{staticClass:"float",attrs:{"id":"approve","disabled":_vm.disableButton()},on:{"click":_vm.showPinModal}},[_c('span',{staticClass:"my-float"},[_vm._v("APPROVE")])]),(_vm.showInputPin)?_c('pin-modal',{attrs:{"close":_vm.onModalClose,"reqParam":_vm.reqParam,"param":_vm.pinParam}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }